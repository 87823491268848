/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useUserModalState from '../states/useUserModalState'
import { Button, Form, Icon, Input, Modal, Segment, TextArea } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'


/**
 * <UserModal   type
 *              userKey [OPTIONAL]
 *              user [OPTIONAL]
 *              mobile [OPTIONAL]
 *              handleAction />
 * 
 * @prop { string } type - action type
 * @prop { string } userKey - object key
 * @prop { object } user - user object
 * @prop {boolean}  mobile - determine mobile view
 * @prop { function } handleAction - function to manipulate user
 */

function UserModal(props) {
    const { type, userKey, user, mobile, userIndex } = props;
    const {
        formState,
        modal,
        loading,
        updateLoading,
        updateEditModal,    
        updateDeleteModal,
        updateFormState,
        resetForm,
        resetModal
    } = useUserModalState();

    /**
     * Initialize data upon editing data.
     */
    const setData = () => {
        if(modal.edit || modal.delete) {
            if(type === 'edit') {
                let emails = '';
                let tracker = user.trackerKey === '' ? '' : `https://drive.google.com/file/d/${user.trackerKey}/view?usp=sharing`
                if(user.email !== undefined) {
                    if(Array.isArray(user.email)) {
                        user.email.forEach((val, index) => {
                            emails += `${parseInt(index) > 0 ? ', ' : ''}${val}`
                        });
                    } else {
                        emails = user.email;
                    }
                    updateFormState(userKey, user.head, emails, tracker);
                } else {
                    updateFormState(userKey, user.head, '', tracker);
                }
            } else if(type === 'create') {
                document.getElementById('user-org-name').focus();
                resetForm();
            }
        }
        updateLoading(false);
    }

    useEffect(setData, [userKey, user, type, modal]);

    /**
     * This function handles creating user.
     */
    const handleCreate = () => {
        props.handleAction(formState);
        resetModal();
    }

    /**
     * This function handles editing user.
     */
    const handleEdit = () => {
        let formStateClone = {...formState};
        formStateClone.trackerKey = formStateClone.trackerKey === '' ? formStateClone.trackerKey : formStateClone.trackerKey.split('d/')[1].split('/')[0];
        if(_.isEqual(formStateClone.userKey, userKey) && 
            _.isEqual(formStateClone.head, user.head) &&
            _.isEqual(formStateClone.email, user.email) &&
            _.isEqual(formStateClone.trackerKey, user.trackerKey)) {
            NotificationManager.info('No changes made.', '', 2000);
        } else {
            props.handleAction(userKey, user, formStateClone);
        }
        resetModal();
    }

    /**
     * This function handles deleting user.
     */
    const handleDelete = () => {
        props.handleAction(userKey);
        resetModal();
    }

    /**
     * This function validates email.
     */
    const handleValidateEmails = async () => {
        if(_.includes(formState.email.split(', '), formState.head)) {
            NotificationManager.error('Duplicate emails found!', '', 2000);
        } else {
            updateLoading(true);
            if(type === 'create') {
                handleCreate();
            } else if(type === 'edit') {
                handleEdit();
            }
        }
    }

    /**
     * This function handles changing values of the fields.
     * @param { event } event - onChange event
     * @param { string } name - name of the component
     * @param { string } value - new value of the field
     */
    const handleChange = (event, { name, value }) => {
        let formStateClone = {...formState};
        formStateClone[name] = value;
        updateFormState(formStateClone.userKey, formStateClone.head, formStateClone.email, formStateClone.trackerKey);
    }

    return (
        type === 'create' || type === 'edit' ? 
            <Modal
                open={modal.edit}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                size='tiny'
                className='web-modal'
                trigger={
                    type === 'create' ?
                        <Button
                            id={'create-user'}
                            className='modal-buttons'
                            content='Create'
                            onClick={() => updateEditModal(true)} />
                    :
                        mobile ? 
                            <Icon
                                id={`edit-user-icon-${userIndex}`}
                                name='edit'
                                onClick={()=> updateEditModal(true)}/>
                        :
                            <Button
                                id={`edit-user-${userIndex}`}
                                color='green'
                                content='Edit'
                                onClick={()=> updateEditModal(true)}/>
                }>
                <Modal.Header className='modal-header'>{type === 'create' ? 'Create Organization' : 'Edit Organization'}</Modal.Header>
                <Modal.Content>
                    <Segment 
                        as={Form} 
                        loading={loading}
                        onSubmit={handleValidateEmails}>
                        <Form.Field 
                            id={'user-org-name'}
                            required
                            control={Input}
                            type='text'
                            label='Organization Name'
                            placeholder='Organization Name'
                            name='userKey'
                            value={formState.userKey}
                            onChange={handleChange} />
                        <Form.Field 
                            id={'user-org-email'}
                            required
                            control={Input}
                            type='email'
                            label={user && user.admin === 1 ? 'Admin Email Address' : 'Treasurer Email Address'}
                            placeholder='Email Address'
                            name='head'
                            value={formState.head}
                            onChange={handleChange} />
                         <Form.Field
                            id='user-org-email-list'
                            control={TextArea}
                            type='text'
                            label='Who Has Access'
                            placeholder='Ex. email1@gmail.com, email2@gmail.com'
                            name='email'
                            value={formState.email}
                            onChange={handleChange} />
                        <Form.Field
                            id={'user-org-trackerKey'}
                            control={Input}
                            type='url'
                            label='Document Tracker Link'
                            placeholder='Document Tracker Link'
                            name='trackerKey'
                            value={formState.trackerKey}
                            onChange={handleChange} />
                        <Button 
                            id='submit-user-form'
                            type='submit'
                            className='invisible-element' />  
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id={'update-user-button'}
                        type='submit'
                        className='modal-buttons'
                        floated='right'
                        disabled={_.isEqual(formState.userKey, '') || _.isEqual(formState.head, '')}
                        content={type === 'create' ? 'Create' : 'Update'}
                        onClick={() => document.getElementById('submit-user-form').click()} />
                    <Button
                        id={'cancel-update-user-button'}
                        type='button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
        :
            <Modal
                open={modal.delete}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                className='web-modal'
                size='mini'
                trigger={
                    mobile ?
                        <Icon
                            id={`delete-user-icon-${userIndex}`}
                            name='delete'
                            onClick={()=> updateDeleteModal(true)}/>
                    :
                        <Button
                            id={`delete-user-${userIndex}`}
                            color='red'
                            content='Delete'
                            onClick={()=> updateDeleteModal(true)}/>
                }>
                <Modal.Header className='modal-header'>Delete Organization</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete <strong>{userKey}</strong>?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id={'delete-user-button'}
                        type='button'
                        className='modal-buttons'
                        floated='right'
                        content='Delete'
                        onClick={handleDelete} />
                    <Button
                        id={'cancel-delete-user-button'}
                        type='button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
    )
}

UserModal.propTypes = {
    type: PropTypes.string,
    userKey: PropTypes.string,
    user: PropTypes.object,
    handleAction: PropTypes.func,
    mobile: PropTypes.bool
}

export default UserModal;