import { useState } from 'react'

export default function useLoginState() {
    const [loading, setLoading] = useState(false);
    const [logged, setLogged] = useState(false);
    const [redirect, setRedirect] = useState(true);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [organizations, setOrganizations] = useState([]);

    return { 
        loading,
        logged,
        redirect,
        selectedOrg,
        organizations,
        updateLoading: setLoading,
        updateLogged: setLogged,
        updateRedirect: setRedirect,
        updateSelectedOrg: setSelectedOrg,
        updateOrganizations: setOrganizations
    }
}