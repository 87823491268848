import { useState } from 'react'

export default function useUserModalState() {
    const [formState, setFormState] = useState({ userKey: '', head: '', email: '', trackerKey: '' });
    const [modal, setModal] = useState({ edit: false, delete: false });
    const [loading, setLoading] = useState(false);

    const handleUpdateFormState = (userKey, head, email, trackerKey ) => {
        setFormState({ userKey, head, email, trackerKey });
    }

    const handleUpdateEditModal = (value) => {
        setModal({...modal, edit: value});
    }

    const handleUpdateDeleteModal = (value) => {
        setModal({...modal, delete: value});
    }

    const handleResetModal = () => {
        setModal({ edit: false, delete: false });
    }
    
    const handleResetForm = () => {
        setFormState({ userKey: '', head: '', email: '', trackerKey: '' });
    }

    return {
        formState,
        modal,
        loading,
        updateLoading: setLoading,
        updateFormState: handleUpdateFormState,
        updateEditModal: handleUpdateEditModal,
        updateDeleteModal: handleUpdateDeleteModal,
        resetModal: handleResetModal,
        resetForm: handleResetForm
    }
}