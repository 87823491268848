import { useState } from 'react'

export default function usePageDescriptionModalState() {
    const [formState, setFormState] = useState({ name: '', description: '' })
    const [modal, setModal] = useState({
        edit: false, delete: false
    });

    const handleUpdateFormState = (name, description) => {
        setFormState({ name, description });
    }

    const handleUpdateEditModal = (value) => {
        setModal({ ...modal, edit: value });
    }

    const handleUpdateDeleteModal = (value) => {
        setModal({ ...modal, delete: value });
    }

    const handleResetModal = () => {
        setModal({ edit: false, delete: false });
    }

    const handleResetForm = () => {
        setFormState({ name: '', description: '' });
    }

    return {
        formState,
        modal,
        updateFormState: handleUpdateFormState,
        updateEditModal: handleUpdateEditModal,
        updateDeleteModal: handleUpdateDeleteModal,
        resetModal: handleResetModal,
        resetForm: handleResetForm
    }
}