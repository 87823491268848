import { useState } from 'react'

export default function usePageState() {
    const [loading, setLoading] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(false);
    const [showEditEmail, setShowEditEmail] = useState(false);
    const [fetchBalance, setFetchBalance] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [cards, setCards] = useState({});
    const [announcements, setAnnouncements] = useState({});
    const [faqs, setFaqs] = useState({});
    const [pageDescriptions, setPageDescriptions] = useState({});
    const [users, setUsers] = useState({});
    const [documents, setDocuments] = useState([]);
    const [spf, setSpf] = useState([]);
    const [balance, setBalance] = useState([]);
    const [debts, setDebts] = useState([]);

    return {
        loading,
        showSidebar,
        showMenu,
        showEditProfile,
        showEditEmail,
        fetchBalance,
        currentUser,
        cards,
        announcements,
        documents,
        spf,
        balance,
        debts,
        faqs,
        pageDescriptions,
        users,
        updateLoading: setLoading,
        updateShowSidebar: setShowSidebar,
        updateShowMenu: setShowMenu,
        updateShowEditProfile: setShowEditProfile,
        updateShowEditEmail: setShowEditEmail,
        updateFetchBalance: setFetchBalance,
        updateCurrentUser: setCurrentUser,
        updateCards: setCards,
        updateAnnouncements: setAnnouncements,
        updateDocuments: setDocuments,
        updateSpf: setSpf,
        updateBalance: setBalance,
        updateDebts: setDebts,
        updateFaqs: setFaqs,
        updatePageDescriptions: setPageDescriptions,
        updateUsers: setUsers
    }
}