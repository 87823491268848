import Login from '../modules/Login'
import Account from '../modules/account'

export default [
    {
        key: '/',
        path: '/',
        component: Login,
        exact: true
    },
    {
        key: '/:name',
        path: '/:name',
        component: Account,
        exact: false
    },
]