/* REACT AND CSS */
import React from 'react'
import { Grid, Image, Progress, Responsive } from 'semantic-ui-react'

/* COMPONENTS AND ASSETS */
import loading from '../assets/cso_loading.gif'

/**
 * <Redirect />
 */
function Redirect() {
    return (
        <div className='login-container'>
            <Grid 
                className='login-grid' 
                relaxed 
                columns='equal'
                verticalAlign='middle'
                textAlign='center'>
                <Grid.Row>
                    <Responsive 
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                    <Grid.Column>
                        <Image 
                            className='login-logo'
                            centered
                            src={loading}
                            size='medium' />                        
                        <Progress 
                            percent={100}
                            inverted
                            active>
                            {localStorage.getItem('message')}
                        </Progress>
                        <br />
                    </Grid.Column>
                    <Responsive 
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default Redirect;