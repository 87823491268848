/* REACT AND CSS */
import React, { useEffect } from 'react'
import { Grid, Header, List, Responsive } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types' 

/* EXTERNAL LIBRARIES */
import { API } from '../../utils/API'

/* COMPONENTS AND ASSETS */
import UserItem from '../../components/UserItem'
import UserModal from '../../components/UserModal'

/**
 *   Settings   users
 *              updateUsers
 *              updateLoading
 *              handleLogout />
 * 
 * @prop { object } users - dictionary of users
 * @prop { function } updateUsers - function to update list of users
 * @prop { function } updateLoading - function to update loading state
 * @prop { function } handleLogout - function to logout user
 */

function Settings(props) {
    const { users } = props;

    /**
     * This function handles fetching users on switch to home page.
     */
    const fetchData = () => {
        props.updateLoading(true);
        let auth = new URLSearchParams({
            token: localStorage.getItem('token')
        })
        API.users.get(auth, (responses) => {
            if(responses.success) {
                props.updateUsers(responses.data.users);
                localStorage.setItem('session', responses.data.user.username || '')
                localStorage.setItem('admin', responses.data.user.admin)
            }
            else {
                if(responses.data.user == null){
                    NotificationManager.info(responses.message);
                    props.handleLogout(); 
                }
            } 
            props.updateLoading(false);
        })
    }

    useEffect(fetchData, []);

    /**
     * This function handles updating list of users.
     * @param { object } response - back-end response
     */
    const updateUsers = (response) => {
        if(response.success) {
            NotificationManager.success(response.message, 'Success', 2000);
            props.updateUsers(response.data.users);
        } else {
            NotificationManager.error(response.message, 'Error', 2000);
        }
        props.updateLoading(false);
    }

    /**
     * This function handles creating a user.
     * @param { object } value - user object
     */
    const handleCreateUser = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            org: value.userKey,
            head: value.head,
            trackerKey: value.trackerKey,
            email: value.email
        });
        API.users.create(data, (response) => {
            updateUsers(response);
        })
    }

    /**
     * This function handles updating a user.
     * @param { string } key - object key
     * @param { object } object - user object
     * @param { object } value - new user object
     */
    const handleUpdateUser = (key, object, value) => {
        let newUser = {...object};
        delete newUser.username;
        newUser.head = value.head;
        newUser.trackerKey = value.trackerKey;
        let list = {};
        if(value.email !== '') {
            value.email.split(', ').forEach((val, index) => {
                list[index] = val;
            })
        }
        newUser.email = list;
        props.updateLoading(true);
        let data = new URLSearchParams({
            previous: key,
            username: value.userKey,
            user: JSON.stringify(newUser),
        });
        API.users.update(data, (response) => {
            updateUsers(response);
        })
    }

    /**
     * This function handles deleting a user.
     * @param { object } value - user object
     */
    const handleDeleteUser = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            username: value
        });
        API.users.delete(data, (response) => {
            updateUsers(response);
        })
    }

    return (
        <div className='page-content'>
            <Grid 
                relaxed
                divided
                columns='equal'
                className='web-grid'
                stackable>
                <Grid.Row>
                    <Responsive
                        as={Grid.Column} 
                        minWidth={Responsive.onlyComputer.minWidth}
                        className='settings-title'>
                            <br />
                            <Header as='h2'>
                                Settings
                                <Header.Subheader>
                                    Manage your student organization accounts.
                                </Header.Subheader>
                            </Header><br></br>
                            <UserModal type='create' handleAction={handleCreateUser}/>
                    </Responsive>  
                    <Responsive
                        as={Grid.Column}
                        maxWidth={Responsive.onlyTablet.maxWidth}
                        className='settings-title'
                        text-align='center'>
                            <Header as='h2'>
                                Settings
                                <Header.Subheader>
                                Manage your student organization accounts.
                                </Header.Subheader>
                            </Header> <br></br>
                            <UserModal type='create' handleAction={handleCreateUser}/>
                    </Responsive>  
                    <Responsive
                        as={Grid.Column}
                        minWidth={Responsive.onlyComputer.minWidth}
                        width={10}>
                        <List
                            divided
                            relaxed
                            verticalAlign='middle' >
                            {Object.keys(users).map((key, index) => (
                                key !== localStorage.getItem('session') && 
                                <UserItem 
                                    key={index} 
                                    user={users[key]} 
                                    userKey={key}
                                    userIndex={index}
                                    handleUpdate={handleUpdateUser}
                                    handleDelete={handleDeleteUser} />  
                            ))}
                        </List>
                    </Responsive>
                </Grid.Row>
                <Responsive
                    as={Grid.Row}
                    maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Grid.Column>
                        <List
                            divided
                            relaxed
                            verticalAlign='middle' >
                            {Object.keys(users).map((key, index) => (
                                key !== localStorage.getItem('session') && 
                                <UserItem 
                                    key={index} 
                                    user={users[key]} 
                                    userKey={key}
                                    userIndex={index}
                                    handleUpdate={handleUpdateUser}
                                    handleDelete={handleDeleteUser} />  
                            ))}
                        </List>
                    </Grid.Column>
                </Responsive>
            </Grid>       
        </div>
    )
}


Settings.propTypes = {
    users: PropTypes.object,
    updateUsers : PropTypes.func,
    updateLoading : PropTypes.func,
    handleLogout: PropTypes.func
}

export default Settings;