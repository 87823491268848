import { useState } from 'react'

export default function useDebtsState() {
    const [headers, setHeaders] = useState([]);
    const [list, setList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    return {
        headers,
        list,
        activePage,
        totalPages,
        updateHeaders: setHeaders,
        updateList: setList,
        updateActivePage: setActivePage,
        updateTotalPages: setTotalPages
    }
}