/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid, Header } from 'semantic-ui-react'

/* EXTERNAL LIBRARIES */
import moment from 'moment'

/* COMPONENTS AND ASSETS */
import AnnouncementModal from './AnnouncementModal'

/**
 * <AnnouncementItem    announcementKey
 *                      announcement
 *                      announcementIndex
 *                      handleUpdate
 *                      handleDelete />
 * 
 * @prop { string } announcementKey - object key
 * @prop { object } announcement - announcement object
 * @prop { integer } announcementIndex - index of announcement
 * @prop { function } handleUpdate - function to update an announcement
 * @prop { function } handleDelete - function to delete an announcement
 */
function AnnouncementItem(props) {
    const { announcement, announcementKey, announcementIndex } = props;

    return (
        <Card fluid>
            <Grid
                className='web-grid'
                relaxed
                columns='equal'>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={localStorage.getItem('admin') === '0' ? 12 : 16}>
                        <Header
                            as='h4'
                            content={announcement.title}
                            className='compact-element' />
                    </Grid.Column>
                    {localStorage.getItem('admin') === '0' && 
                        moment.duration(moment().diff(moment(announcement.dateTime, 'YYYY-MM-DD HH:mm:ss'))).asDays() < 1 ?
                        <Grid.Column textAlign='right'>
                            <Header
                                as='h4'
                                content='NEW!'
                                color='green'
                                className='compact-element' />
                        </Grid.Column>
                    : null}
                </Grid.Row>
                <Grid.Row className='compact-element'>
                    <Grid.Column>
                        <Divider className='compact-element' section />
                    </Grid.Column>
                </Grid.Row>
                <br />
                <Grid.Row className='compact-element'>
                    <Grid.Column>
                        <Card.Content>
                            <Card.Description>{announcement.body}</Card.Description>
                            <br />
                            <Card.Meta>{
                                `Posted on ${moment(announcement.dateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}
                                at ${moment(announcement.dateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}`
                            }</Card.Meta>
                        </Card.Content>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {localStorage.getItem('admin') === '1' ?
                        <Grid.Column>
                            <div className='announcement-action-wrapper'>
                                <AnnouncementModal
                                    type='edit'
                                    announcementIndex={announcementIndex}
                                    announcementKey={announcementKey}
                                    announcement={announcement}
                                    handleAction={props.handleUpdate} />
                                <AnnouncementModal
                                    type='delete'
                                    announcementIndex={announcementIndex}
                                    announcementKey={announcementKey}
                                    announcement={announcement}
                                    handleAction={props.handleDelete} />
                            </div>
                        </Grid.Column>
                    : null}
                </Grid.Row>
            </Grid>
        </Card>
    )
}

AnnouncementItem.propTypes = {
    announcementKey: PropTypes.string,
    announcement: PropTypes.object,
    announcementIndex: PropTypes.number,
    handleUpdate: PropTypes.func,
    handleDelete: PropTypes.func
}

export default AnnouncementItem;