import axios from 'axios'
axios.defaults.withCredentials = true;

/* PRODUCTION */
const URL = 'https://cso-fin-api.herokuapp.com';
/* DEVELOPMENT */
// const URL = 'http://localhost:8000';

const API = {
    getOrganization: (data, callback) => {
        axios.post(`${URL}/api/auth/get-org`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        })
    },
    validateAdminEmail: async (data, callback) => {
        await axios.post(`${URL}/api/auth/validate-admin-email`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        })
    },
    login: (data, callback) => {
        axios.post(`${URL}/api/auth/login`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    logout: (callback) => {
        axios.post(`${URL}/api/auth/logout`).then(res => {
            callback();
        })
    },
    validateUser: (data, callback) => {
        axios.post(`${URL}/api`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getHomeData: (data, callback) => {
        axios.post(`${URL}/api/account/get-home-data`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getHelpData: (data, callback) => {
        axios.post(`${URL}/api/account/get-help-data`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getAllDocuments: (data, callback) => {
        axios.post(`${URL}/api/document/get-all`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getSPF: (data, callback) => {
        axios.post(`${URL}/api/document/get-spf`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getBalance: (data, callback) => {
        axios.post(`${URL}/api/document/get-balance`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    getDebts: (data, callback) => {
        axios.post(`${URL}/api/document/get-debts`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
            callback(res.data);
        });
    },
    users: {
        get: (data, callback) => {
            axios.post(`${URL}/api/account/get-all`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        create: (data, callback) => {
            axios.post(`${URL}/api/account/create`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        update: (data, callback) => {
            axios.post(`${URL}/api/account/update`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        delete: (data, callback) => {
            axios.post(`${URL}/api/account/delete`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        }
    },
    cards: {
        get: (callback) => {
            axios.get(`${URL}/api/card/get-all`).then(res => {
                callback(res.data);
            });
        },
        create: (data, callback) => {
            axios.post(`${URL}/api/card/create`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        update: (data, callback) => {
            axios.post(`${URL}/api/card/update`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        delete: (data, callback) => {
            axios.post(`${URL}/api/card/delete`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        }
    },
    announcements: {
        get: (callback) => {
            axios.get(`${URL}/api/announcement/get-all`).then(res => {
                callback(res.data);
            });
        },
        create: (data, callback) => {
            axios.post(`${URL}/api/announcement/create`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        update: (data, callback) => {
            axios.post(`${URL}/api/announcement/update`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        delete: (data, callback) => {
            axios.post(`${URL}/api/announcement/delete`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        }
    },
    faqs: {
        get: (callback) => {
            axios.get(`${URL}/api/faq/get-all`).then(res => {
                callback(res.data);
            });
        },
        create: (data, callback) => {
            axios.post(`${URL}/api/faq/create`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        update: (data, callback) => {
            axios.post(`${URL}/api/faq/update`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        delete: (data, callback) => {
            axios.post(`${URL}/api/faq/delete`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        }
    },
    pageDescriptions: {
        get: (callback) => {
            axios.get(`${URL}/api/page-description/get-all`).then(res => {
                callback(res.data);
            });
        },
        create: (data, callback) => {
            axios.post(`${URL}/api/page-description/create`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        update: (data, callback) => {
            axios.post(`${URL}/api/page-description/update`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        },
        delete: (data, callback) => {
            axios.post(`${URL}/api/page-description/delete`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {
                callback(res.data);
            });
        }
    },
}

export { URL, axios, API }