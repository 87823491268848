/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Grid, Header, Responsive } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import useHelpState from '../../states/useHelpState'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../../utils/API'

/* COMPONENTS AND ASSETS */
import PageDescriptionItem from '../../components/PageDescriptionItem'
import PageDescriptionModal from '../../components/PageDescriptionModal'
import FAQItem from '../../components/FAQItem'
import FAQModal from '../../components/FAQModal'

/**
 * <Help    faqs
 *          pageDescriptions
 *          updateFaqs
 *          updatePageDescriptions
 *          updateLoading
 *          handleLogout />
 * 
 * @prop { object } faqs - dictionary of faqs
 * @prop { object } pageDescriptions - dictionary of page descriptions
 * @prop { function } updateFaqs - function to update list of faqs
 * @prop { function } updatePageDescriptions - function to update list of page descriptions
 * @prop { function } updateLoading - function to update loading state
 * @prop { function } handleLogout - function to logout user
 */
function Help(props) {
    const { faqs, pageDescriptions } = props;
    const {
        faqActiveIndex,
        pageDescriptionActiveIndex,
        updateFaqActiveIndex,
        updatePageDescriptionActiveIndex
    } = useHelpState();

    /**
     * This function calls back-end and fetch data upon rendering.
     */
    const fetchData = () => {
        props.updateLoading(true);
        API.getHelpData(new URLSearchParams({
            token: localStorage.getItem('token')
        }), (response) => {
            if(response.success) {
                props.updateFaqs(response.data.faqs);
                props.updatePageDescriptions(response.data.pages);
                props.updateLoading(false);
            } else {
                NotificationManager.info(response.message);
                props.handleLogout();
            }
        })
    }

    useEffect(fetchData, []);

    /**
     * This function handles updating list of faqs.
     * @param { object } response - back-end response
     */
    const updateFaqs = (response) => {
        if(response.success) {
            NotificationManager.success(response.message, 'Success', 2000);
            props.updateFaqs(response.data.faqs);
        } else {
            NotificationManager.error(response.message, 'Error', 2000);
        }
        props.updateLoading(false);
    }

    /**
     * This function handles updating list of page descriptions.
     * @param { object } response - back-end response
     */
    const updatePageDescriptions = (response) => {
        if(response.success) {
            NotificationManager.success(response.message, 'Success', 2000);
            props.updatePageDescriptions(response.data.pages);
        } else {
            NotificationManager.error(response.message, 'Error', 2000);
        }
        props.updateLoading(false);
    }

    /**
     * This function handles creating an FAQ.
     * @param { object } value - faq object
     */
    const handleCreateFaq = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            question: value.question,
            answer: value.answer
        });
        API.faqs.create(data, (response) => {
            updateFaqs(response);
        })
    }

    /**
     * This function handles updating an FAQ.
     * @param { string } key - object key
     * @param { object } value - faq object
     */
    const handleUpdateFaq = (key, value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key,
            question: value.question,
            answer: value.answer
        });
        API.faqs.update(data, (response) => {
            updateFaqs(response);
        })
    }

    /**
     * This function handles deleting an FAQ.
     * @param { object } value - faq object
     */
    const handleDeleteFaq = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key: value
        });
        API.faqs.delete(data, (response) => {
            updateFaqs(response);
        })
    }

    /**
     * This function handles creating a page description.
     * @param { object } value - pagedescription object
     */
    const handleCreatePageDescription = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            name: value.name,
            description: value.description
        });
        API.pageDescriptions.create(data, (response) => {
            updatePageDescriptions(response);
        })
    }

    /**
     * This function handles updating a page description.
     * @param { string } key - object key
     * @param { object } value - pagedescription object
     */
    const handleUpdatePageDescription = (key, value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key,
            name: value.name,
            description: value.description
        });
        API.pageDescriptions.update(data, (response) => {
            updatePageDescriptions(response);
        })
    }

    /**
     * This function handles deleting a page description.
     * @param { object } value - page description object
     */
    const handleDeletePageDescription = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key: value
        });
        API.pageDescriptions.delete(data, (response) => {
            updatePageDescriptions(response);
        })
    }

    return (
        <div className='page-content'>
            <Grid
                className='web-grid'
                textAlign='center'
                relaxed
                columns='equal'>
                <Grid.Row>
                    <Responsive
                        as={Grid.Column}
                        minWidth={Responsive.onlyComputer.minWidth}
                        textAlign='left'
                        width={6}>
                        <br />
                        <Header as='h2'>
                            Help
                            <Header.Subheader>
                                Guidelines for our website.
                            </Header.Subheader>
                        </Header><br></br>
                        <Header as='h3'>
                            Contact Details
                            <Header.Subheader>cso.financeteam@gmail.com</Header.Subheader>
                        </Header>
                    </Responsive>
                    <Responsive
                        as={Grid.Column}
                        maxWidth={Responsive.onlyTablet.maxWidth}
                        width={16}>
                        <Header as='h2'>
                            Help
                            <Header.Subheader>
                                Guidelines for our website.
                            </Header.Subheader>
                        </Header><br></br>
                        <br />
                    </Responsive>
                    <Grid.Column className='compact-element'>
                        <Grid
                            className='web-grid'
                            relaxed
                            columns='equal'
                            verticalAlign='middle'>
                            <Grid.Row className='compact-element'>
                                <Grid.Column>
                                    <Header 
                                        as='h5' 
                                        content='Frequently Asked Questions' 
                                        textAlign='left' />
                                </Grid.Column>
                                {localStorage.getItem('admin') === '1' ?
                                    <Grid.Column textAlign='right' width={4}>
                                        <FAQModal type='create' handleAction={handleCreateFaq} />
                                    </Grid.Column>
                                : null}
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='left'>
                                    <Accordion styled fluid>
                                        {!_.isEmpty(faqs) && Object.keys(faqs).map((key, index) => (
                                            <FAQItem
                                                key={index}
                                                faq={faqs[key]}
                                                faqKey={key}
                                                faqIndex={index}
                                                faqActiveIndex={faqActiveIndex}
                                                updateFaqActiveIndex={updateFaqActiveIndex}
                                                handleUpdateFaq={handleUpdateFaq}
                                                handleDeleteFaq={handleDeleteFaq} />
                                        ))}
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br />
                        <Grid
                            className='web-grid'
                            relaxed
                            columns='equal'
                            verticalAlign='middle'>
                            <Grid.Row className='compact-element'>
                                <Grid.Column>
                                    <Header 
                                        as='h5' 
                                        content='Page Descriptions' 
                                        textAlign='left' />
                                </Grid.Column>
                                {localStorage.getItem('admin') === '1' ?
                                    <Grid.Column textAlign='right' width={4}>
                                        <PageDescriptionModal type='create' handleAction={handleCreatePageDescription} />
                                    </Grid.Column>
                                : null}
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='left'>
                                    <Accordion styled fluid>
                                        {!_.isEmpty(pageDescriptions) && Object.keys(pageDescriptions).map((key, index) => (
                                            <PageDescriptionItem
                                                key={index}
                                                pageDescription={pageDescriptions[key]}
                                                pageDescriptionKey={key}
                                                pageDescriptionIndex={index}
                                                pageDescriptionActiveIndex={pageDescriptionActiveIndex}
                                                updatePageDescriptionActiveIndex={updatePageDescriptionActiveIndex}
                                                handleUpdatePageDescription={handleUpdatePageDescription}
                                                handleDeletePageDescription={handleDeletePageDescription} />
                                        ))}
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <br />
                <br />
                <Responsive
                    as={Grid.Row}
                    maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Grid.Column>
                        <Header as='h4'>
                            Contact Details
                            <Header.Subheader>cso.financeteam@gmail.com</Header.Subheader>
                        </Header>
                    </Grid.Column>
                </Responsive>
            </Grid>
        </div>
    )
}

Help.propTypes = {
    faqs: PropTypes.object,
    pageDescriptions: PropTypes.object,
    updateFaqs: PropTypes.func,
    updatePageDescriptions: PropTypes.func,
    updateLoading: PropTypes.func,
    handleLogout: PropTypes.func
}

export default Help;