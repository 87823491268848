import Home from './Home'
import DocumentTracker from './DocumentTracker'
import Debts from './Debts'
import Help from './Help'
import Settings from './Settings'
import SPFTracker from './SPFTracker'

export default [
    {
        key: '/',
        path: '/',
        component: Home,
        exact: true
    },
    {
        key: '/document-tracker',
        path: '/document-tracker',
        component: DocumentTracker,
        exact: false
    },
    {
        key: '/spf-tracker',
        path: '/spf-tracker',
        component: SPFTracker,
        exact: false
    },
    {
        key: '/debts',
        path: '/debts',
        component: Debts,
        exact: false
    },
    {
        key: '/help',
        path: '/help',
        component: Help,
        exact: false
    },
    {
        key: '/settings',
        path: '/settings',
        component: Settings,
        exact: false
    },
]