/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

/**
 * <TitleComponent  title />
 * 
 * @prop { string } title - Tab page name
 */
function Title(props) {
    const { title } = props;
    const defaultTitle = 'CSO Finance';
    
    return (
        <Helmet>
            <title>{title + ' | ' + defaultTitle}</title>
        </Helmet>
    )
}

Title.propTypes = {
    title: PropTypes.string
}

export default Title;