/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useBalanceModalState from '../states/useBalanceModalState'
import { Accordion, Button, Dropdown, Grid, Modal, Responsive, Segment, Table } from 'semantic-ui-react'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import moment from 'moment'
import { API } from '../utils/API'

/**
 * <BalanceModal    balance
 *                  documents
 *                  fetchBalance
 *                  updateBalance
 *                  updateFetchBalance
 *                  updateShowMenu />
 * 
 * @prop { array } balance - array of balance
 * @prop { boolean } fetchBalance - state for fetching balance
 * @prop { function } updateBalance - function to update balance
 * @prop { function } updateFetchBalance - function to update fetching balance state
 * @prop { function } updateShowMenu - function to update dropdown menu state
 */
function BalanceModal(props) {
    const { 
        modal, 
        estimated,
        expenses,
        income,
        showExpenses,
        showIncome,
        updateModal,
        updateEstimated,
        updateExpenses,
        updateIncome,
        updateShowExpenses,
        updateShowIncome
    } = useBalanceModalState();
    const { documents, balance, fetchBalance } = props;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP'
    });

    /**
     * This function fetch organization balance.
     */
    const fetchData = () => {
        if(modal) {
            props.updateFetchBalance(true);
            let auth = new URLSearchParams({
                token: localStorage.getItem('token')
            })
            API.getBalance(auth, (response) => {
                if(response.data.documents && response.data.documents.length > 0) {
                    let data = JSON.parse(response.data.documents);
                    props.updateBalance(data.filter(val => val['Amount'] !== undefined));
                }
                props.updateFetchBalance(false);
            })
            updateShowExpenses(false);
            updateShowIncome(false);
        }
    }

    useEffect(fetchData, [modal]);

    /**
     * This function compute the current estimated balance.
     */
    const computeEstimatedBalance = () => {
        if(documents.length > 0 && balance.length > 0) {
            let estimatedBalance = parseFloat(_.last(balance)['Amount'].replace(',', ''));
            let expensesList = [...documents].filter(val => val['Amount Spent/ to be Spent'] !== '' && 
                val['Date Received by Accounting Office/Establishment'] !== undefined &&
                val['Date Received by Accounting Office/Establishment'] !== '');
            let incomeList = [...documents].filter(val => val['Type of Process'] === 'Fund Raising Activity Report (FRA)' &&
                val['Remarks'] !== undefined && val['Remarks'] === 'Approved');
            if(expensesList.length > 0) {
                expensesList = expensesList.map((val, index) => {
                    val['Amount Spent/ to be Spent'] = val['Amount Spent/ to be Spent'] === '' ? '0' : val['Amount Spent/ to be Spent']
                    return {
                        key: index,
                        activity: val['Title of Activity'],
                        expenses: parseFloat(val['Amount Spent/ to be Spent'].replace(',', ''))
                    }
                })
                _.each(expensesList, (val => {
                    estimatedBalance -= val.expenses;
                }))
            }
            if(incomeList.length > 0) {
                incomeList = incomeList.map((val, index) => {
                    val['Actual Revenues'] = val['Actual Revenues'] === '' ? '0' : val['Actual Revenues']
                    val['Expenses from Sales'] = val['Expenses from Sales'] === '' ? '0' : val['Expenses from Sales']
                    val['Expenses from Disbursements'] = val['Expenses from Disbursements'] === '' ? '0' : val['Expenses from Disbursements']
                    return {
                        key: index,
                        activity: val['Title of Activity'],
                        income: parseFloat(val['Actual Revenues'].replace(',', '')) - (parseFloat(val['Expenses from Sales'].replace(',', '')) + parseFloat(val['Expenses from Disbursements'].replace(',', '')))
                    }
                })
                _.each(incomeList, (val => {
                    estimatedBalance += val.income;
                }))
            }
            updateEstimated(estimatedBalance);
            updateExpenses(expensesList);
            updateIncome(incomeList);
        }
    }

    useEffect(computeEstimatedBalance, [documents, balance]);

    /**
     * This function shows balance modal and hides profile menu.
     */
    const showModal = () => {
        props.updateShowMenu(false);
        updateModal(true);
    }

    /**
     * This function handles closing modal.
     */
    const closeModal = () => {
        props.updateShowMenu(false);
        updateModal(false);
    }

    return (
        <Modal
            id='view-balance-modal'
            className='web-modal'
            size='tiny'
            open={modal}
            onClose={closeModal}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            trigger={
                <Dropdown.Item 
                    id='view-balance'
                    icon='payment' 
                    text='View Balance'
                    onClick={showModal} />
            } >
            <Modal.Header id='view-balance-modal-header' className='modal-header'>Organization Balance</Modal.Header>
            <Modal.Content id='view-balance-modal-content' scrolling>
                {!fetchBalance && balance.length > 0 ? 
                    <>
                        <Table 
                            id='view-balance-table'
                            size='small' 
                            textAlign='center'>
                            <Responsive as={Table.Header} minWidth={Responsive.onlyTablet.minWidth}>
                                <Table.Row>
                                    <Table.HeaderCell>As of</Table.HeaderCell>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                </Table.Row>
                            </Responsive>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{moment(_.last(balance)['Month/Year'], 'MMMM DD, YYYY').format('MMMM DD, YYYY')}</Table.Cell>
                                    <Table.Cell>{formatter.format(parseFloat(_.last(balance)['Amount'].replace(',', '')))}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Estimated Current Balance</Table.Cell>
                                    <Table.Cell>
                                        {expenses.length > 0 ? formatter.format(estimated) : 
                                        formatter.format(parseFloat(_.last(balance)['Amount'].replace(',', '')))}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Accordion 
                            id='expenses-breakdown'
                            styled 
                            fluid>
                            <Accordion.Title 
                                icon='dropdown'
                                active={showExpenses} 
                                onClick={() => updateShowExpenses(!showExpenses)}
                                content='Breakdown of Expenses' />
                            <Accordion.Content active={showExpenses}>
                                {expenses.length > 0 ?
                                    <>
                                        <Responsive 
                                            as={Table} 
                                            minWidth={Responsive.onlyTablet.minWidth}
                                            size='small'
                                            celled>
                                            <Table.Body>
                                                {expenses.map((val, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell width='10'>{val.activity}</Table.Cell>
                                                        <Table.Cell textAlign='right'>{formatter.format(val.expenses)}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Responsive>
                                        <Responsive 
                                            as={Table} 
                                            maxWidth={Responsive.onlyMobile.maxWidth}
                                            size='small'
                                            celled
                                            textAlign='center'>
                                            <Table.Body>
                                                {expenses.map((val, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{val.activity}</Table.Cell>
                                                        <Table.Cell>{formatter.format(val.expenses)}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Responsive>
                                    </>
                                : 
                                    <Segment 
                                        basic
                                        textAlign='center' 
                                        content='None' />
                                }
                            </Accordion.Content>
                        </Accordion>
                        <br />
                        <Accordion 
                            id='income-breakdown'
                            styled 
                            fluid>
                            <Accordion.Title 
                                icon='dropdown'
                                active={showIncome} 
                                onClick={() => updateShowIncome(!showIncome)}
                                content='Generated Income' />
                            <Accordion.Content active={showIncome}>
                                {income.length > 0 ?
                                    <>
                                        <Responsive 
                                            as={Table} 
                                            minWidth={Responsive.onlyTablet.minWidth}
                                            size='small'
                                            celled>
                                            <Table.Body>
                                                {income.map((val, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell width='10'>{val.activity}</Table.Cell>
                                                        <Table.Cell textAlign='right'>{formatter.format(val.income)}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Responsive>
                                        <Responsive 
                                            as={Table} 
                                            maxWidth={Responsive.onlyMobile.maxWidth}
                                            size='small'
                                            celled
                                            textAlign='center'>
                                            <Table.Body>
                                                {income.map((val, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{val.activity}</Table.Cell>
                                                        <Table.Cell>{formatter.format(val.income)}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Responsive>
                                    </>
                                : 
                                    <Segment 
                                        basic
                                        textAlign='center' 
                                        content='None' />
                                }
                            </Accordion.Content>
                        </Accordion>
                    </>
                :
                    <Grid
                        id='view-balance-message'
                        className='web-grid'
                        relaxed
                        columns='equal'
                        verticalAlign='middle'
                        textAlign='center'>
                        <Grid.Row>
                            <Grid.Column>
                            {fetchBalance ? 
                                <span>Retrieving balance...</span>
                            :
                                <>
                                    <span>Balance not found.</span><br />
                                    <span>Please contact the admin.</span>
                                </>
                            }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                    id='close-balance-modal'
                    className='modal-buttons'
                    floated='right'
                    content='Close'
                    onClick={closeModal} />
            </Modal.Actions>
        </Modal>
    )
}

BalanceModal.propTypes = {
    balance: PropTypes.array,
    fetchBalance: PropTypes.bool,
    updateBalance: PropTypes.func,
    updateFetchBalance: PropTypes.func,
    updateShowMenu: PropTypes.func
}

export default BalanceModal;