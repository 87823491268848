/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import useSPFItemState from '../states/useSPFItemState'
import { Button, Grid, Item, Label, List, Modal, Table } from 'semantic-ui-react'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import moment from 'moment'

/**
 * <SPFItem     headers
 *              spf
 *              spfIndex />
 * 
 * @prop { array } headers - array of table headers
 * @prop { object } spf - sheet row object
 * @prop { integer } spfIndex - index of document
 */
function SPFItem(props) {
    const { spf, spfIndex, headers } = props;
    const { 
        modal,
        updateModal,
    } = useSPFItemState();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP'
    });

    return (
        <Modal
            className='web-modal'
            open={modal}
            onClose={() => updateModal(false)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size='tiny'
            trigger={
                <List.Item 
                    id={`spf-${spfIndex}`} 
                    className='docu-list' 
                    onClick={() => updateModal(true)}>
                    <Grid
                        className='web-grid'
                        relaxed
                        columns='equal'>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column className='compact-element'>
                                <Item>
                                    <Item.Header as='h4'>{`${spf['Title of Activity']}`}</Item.Header>
                                    {!_.includes(['', undefined], spf['Amount']) ?
                                        <span as='h5'>{formatter.format(parseFloat(spf['Amount'].replace(',', '')))}</span>
                                    : null}
                                </Item>
                            </Grid.Column>
                            <Grid.Column 
                                textAlign='right' 
                                verticalAlign='top'
                                className='compact-element'>
                                <Label
                                    size='large'
                                    color={_.includes(['', undefined], spf['Pended?']) && _.includes(['', undefined], spf['Remarks']) ? 'grey' :
                                    spf['Pended?'] !== '' ? 'red' :
                                        spf['Remarks'] === 'Approved' ? 'green' : 'grey'}
                                    content={_.includes(['', undefined], spf['Pended?']) && _.includes(['', undefined], spf['Remarks']) ? 'Processing' :
                                    spf['Pended?'] !== '' ? 'Pended' :
                                        spf['Remarks'] === 'Approved' ? 'Approved' : 'Processing'} />
                                <br />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='compact-element'>
                            <Grid.Column className='compact-element' textAlign='right'>
                                <Item>
                                    <Item.Description>{
                                        `Submitted on ${moment(spf['Timestamp'], 'M/DD/YYYY HH:mm:ss').format('MMM D, YYYY')}`
                                    }</Item.Description>
                                </Item>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </List.Item>
            }>
            <Modal.Header className='modal-header'>{spf['Title of Activity']}</Modal.Header>
            <Modal.Content scrolling>
                <Table size='small' celled>
                    <Table.Body>
                        {headers.length > 0 && headers.map((val, index) => (
                            <Table.Row key={index}>
                                <Table.Cell width='8'>{val}</Table.Cell>
                                <Table.Cell>{spf['_rawData'][index]}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    id='close-spf-modal'
                    className='modal-buttons'
                    floated='right'
                    content='Close'
                    onClick={() => updateModal(false)} />
            </Modal.Actions>
        </Modal>            
    )
}

SPFItem.propTypes = {
    headers: PropTypes.array,
    spf: PropTypes.object,
    spfIndex: PropTypes.number
}

export default SPFItem;