/* REACT AND CSS */
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-notifications/lib/notifications.css'
import routes from './utils/routes'

function App() {
    const pages = routes.map(route => (
		<Route
			key={route.key}
			exact={route.exact}
			path={route.path}
			component={route.component}
			{...routes} />
	));
	return (
        <Router>
            <NotificationContainer />
			<Switch>{pages}</Switch>
		</Router>
	);
}

export default App;
