/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card, Divider, Grid, Header, Responsive } from 'semantic-ui-react'
import useHomeState from '../../states/useHomeState'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../../utils/API'

/* COMPONENTS AND ASSETS */
import LinkCard from '../../components/LinkCard'
import LinkCardModal from '../../components/LinkCardModal'
import AnnouncementItem from '../../components/AnnouncementItem'
import AnnouncementModal from '../../components/AnnouncementModal'

/**
 * <Home    cards
 *          announcements
 *          updateCards
 *          updateAnnouncements
 *          updateLoading
 *          handleLogout />
 * 
 * @prop { object } cards - dictionary of cards
 * @prop { object } announcements - dictionary of announcements
 * @prop { function } updateCards - function to update list of cards
 * @prop { function } updateAnnouncements - function to update list of announcements
 * @prop { function } updateLoading - function to update loading state
 * @prop { function } handleLogout - function to logout user
 */
function Home(props) {
    const {
        showAnnouncement,
        updateShowAnnouncement
    } = useHomeState();
    const { cards, announcements } = props;

    /**
     * This function handles fetching cards and announcements on switch to home page.
     */
    const fetchData = () => {
        props.updateLoading(true);
        let auth = new URLSearchParams({
            token: localStorage.getItem('token')
        })
        API.getHomeData(auth, (response) => {
            if(response.success) {
                props.updateCards(response.data.cards || {});
                props.updateAnnouncements(response.data.announcements || {});
                localStorage.setItem('session', response.data.user.username);
                localStorage.setItem('admin', response.data.user.admin);
                props.updateLoading(false);
            } else {
                NotificationManager.info(response.message);
                props.handleLogout();
            }
        })
    }

    useEffect(fetchData, []);

    /**
     * This function handles updating list of cards.
     * @param { object } response - back-end response
     */
    const updateCards = (response) => {
        if(response.success) {
            NotificationManager.success(response.message, 'Success', 2000);
            props.updateCards(response.data.cards || {});
        } else {
            NotificationManager.error(response.message, 'Error', 2000);
        }
        props.updateLoading(false);
    }

    /**
     * This function handles updating list of announcements.
     * @param { object } response - back-end response
     */
    const updateAnnouncements = (response) => {
        if(response.success) {
            NotificationManager.success(response.message, 'Success', 2000);
            props.updateAnnouncements(response.data.announcements || {});
        } else {
            NotificationManager.error(response.message, 'Error', 2000);
        }
        props.updateLoading(false);
    }


    /**
     * This function handles creating an announcement.
     * @param { object } value - announcement object
     */
    const handleCreateAnnouncement = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            title: value.title,
            body: value.body
        });
        API.announcements.create(data, (response) => {
            updateAnnouncements(response);
        })
    }

    /**
     * This function handles updating an announcement.
     * @param { string } key - object key
     * @param { object } value - announcement object
     */
    const handleUpdateAnnouncement = (key, value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key,
            title: value.title,
            body: value.body
        });
        API.announcements.update(data, (response) => {
            updateAnnouncements(response);
        })
    }

    /**
     * This function handles deleting an announcement.
     * @param { object } value - announcement object
     */
    const handleDeleteAnnouncement = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key: value
        });
        API.announcements.delete(data, (response) => {
            updateAnnouncements(response);
        })
    }

    /**
     * This function handles creating a card.
     * @param { object } value - card object
     */
    const handleCreateCard = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            title: value.title,
            desc: value.description,
            link: value.link
        });
        API.cards.create(data, (response) => {
            updateCards(response);
        })
    }

    /**
     * This function handles updating a card.
     * @param { string } key - object key
     * @param { object } value - card object
     */
    const handleUpdateCard = (key, value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key,
            title: value.title,
            desc: value.description,
            link: value.link
        });
        API.cards.update(data, (response) => {
            updateCards(response);
        })
    }

    /**
     * This function handles deleting a card.
     * @param { string } value - object key
     */
    const handleDeleteCard = (value) => {
        props.updateLoading(true);
        let data = new URLSearchParams({
            key: value
        });
        API.cards.delete(data, (response) => {
            updateCards(response);
        })
    }

    return (
        <div className='page-content'>
            <Grid 
                className='web-grid'
                relaxed
                stackable
                textAlign='center'
                columns='equal'
                verticalAlign='top'>
                <Responsive 
                    as={Grid.Row} 
                    maxWidth={Responsive.onlyTablet.maxWidth}
                    textAlign='left'>
                    <Accordion styled>
                        <Accordion.Title 
                            active={showAnnouncement} 
                            content='Announcements'
                            onClick={() => updateShowAnnouncement(!showAnnouncement)} />
                        <Accordion.Content active={showAnnouncement}>                            
                            {_.reverse(Object.keys(announcements)).map((key, index) => (
                                <AnnouncementItem 
                                    key={key} 
                                    announcementKey={key}
                                    announcement={announcements[key]}
                                    announcementIndex={index}
                                    handleUpdate={handleUpdateAnnouncement}
                                    handleDelete={handleDeleteAnnouncement} />
                            ))}
                            {_.isEmpty(announcements) ? 
                                <Grid
                                    columns='equal'
                                    relaxed
                                    textAlign='center'
                                    verticalAlign='top'
                                    className='web-grid'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <span>No announcement found.</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            : null}
                            <Grid
                                className='web-grid'
                                relaxed
                                columns='equal'
                                verticalAlign='middle'>
                                <Grid.Row className='compact-element'>
                                    {localStorage.getItem('admin') === '1' ? 
                                        <Grid.Column textAlign='center' className='compact-element'>
                                            <AnnouncementModal type='create' handleAction={handleCreateAnnouncement} />
                                        </Grid.Column>
                                    : null}
                                </Grid.Row>
                            </Grid>
                        </Accordion.Content>
                    </Accordion>
                </Responsive>
                <Grid.Row>
                    <Responsive 
                        as={Grid.Column}
                        minWidth={Responsive.onlyComputer.minWidth}
                        width={10}>
                        <CardsGrid
                            cards={cards}
                            handleCreateCard={handleCreateCard}
                            handleUpdateCard={handleUpdateCard}
                            handleDeleteCard={handleDeleteCard}
                            fluid={false} />
                    </Responsive>
                    <Responsive 
                        as={Grid.Column}
                        maxWidth={Responsive.onlyComputer.minWidth}
                        width={16}>
                        <CardsGrid
                            cards={cards}
                            handleCreateCard={handleCreateCard}
                            handleUpdateCard={handleUpdateCard}
                            handleDeleteCard={handleDeleteCard}
                            fluid={true} />
                    </Responsive>
                    <Responsive 
                        as={Grid.Column} 
                        minWidth={Responsive.onlyComputer.minWidth}
                        textAlign='left'>
                        <Grid
                            className='web-grid'
                            relaxed
                            columns='equal'
                            verticalAlign='middle'>
                            <Grid.Row>
                                <Grid.Column className='compact-element'>
                                    <Header 
                                        as='h3'
                                        icon='announcement'
                                        content='Announcements'
                                        className='compact-element'/>
                                </Grid.Column>
                                {localStorage.getItem('admin') === '1' ? 
                                    <Grid.Column textAlign='right' className='compact-element'>
                                        <AnnouncementModal type='create' handleAction={handleCreateAnnouncement} />
                                    </Grid.Column>
                                : null}
                            </Grid.Row>
                        </Grid>
                        <Divider className='compact-element' section />
                        {_.reverse(Object.keys(announcements)).map((key, index) => (
                            <AnnouncementItem 
                                key={key} 
                                announcementKey={key}
                                announcementIndex={index}
                                announcement={announcements[key]}
                                handleUpdate={handleUpdateAnnouncement}
                                handleDelete={handleDeleteAnnouncement} />
                        ))}
                        {_.isEmpty(announcements) ? 
                            <Grid
                                columns='equal'
                                relaxed
                                textAlign='center'
                                verticalAlign='top'
                                className='web-grid'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span>No announcement found.</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        : null}
                    </Responsive>
                </Grid.Row>
            </Grid>
        </div>
    )
}

Home.propTypes = {
    cards: PropTypes.object,
    announcements: PropTypes.object,
    updateCards: PropTypes.func,
    updateAnnouncements: PropTypes.func,
    updateLoading: PropTypes.func,
    handleLogout: PropTypes.func
}

function CardsGrid(props) {
    const { cards, handleCreateCard, handleUpdateCard, handleDeleteCard, fluid } = props

    return (
        <Grid
            className='web-grid'
            relaxed
            stackable
            columns='equal'
            verticalAlign='top'>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <span className='cards-title'>Below are the important links that you can access from the CSO Finance Directory.</span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Card.Group centered>
                        <CardsElement
                            cards={cards}
                            handleCreateCard={handleCreateCard}
                            handleUpdateCard={handleUpdateCard}
                            handleDeleteCard={handleDeleteCard}
                            fluid={fluid} />
                    </Card.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

CardsGrid.propTypes = {
    cards: PropTypes.object,
    handleCreateCard: PropTypes.func,
    handleUpdateCard: PropTypes.func,
    handleDeleteCard: PropTypes.func,
    fluid: PropTypes.bool
}

function CardsElement(props) {
    const { cards, handleCreateCard, handleUpdateCard, handleDeleteCard, fluid } = props

    return (
        <React.Fragment>
            {Object.keys(cards).map((key, index) => (
                <LinkCard
                    key={key}
                    cardKey={key} 
                    card={cards[key]}
                    cardIndex={index}
                    handleUpdate={handleUpdateCard}
                    handleDelete={handleDeleteCard}
                    fluid={fluid} /> 
            ))}
            {localStorage.getItem('admin') === '1' ?
                <LinkCardModal type='create' handleAction={handleCreateCard} />
            : null}
        </React.Fragment>
    )
}

CardsElement.propTypes = {
    cards: PropTypes.object,
    handleCreateCard: PropTypes.func,
    handleUpdateCard: PropTypes.func,
    handleDeleteCard: PropTypes.func,
    fluid: PropTypes.bool
}

export default Home;