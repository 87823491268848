import { useState } from 'react'

export default function useBalanceModalState() {
    const [modal, setModal] = useState(false);
    const [showExpenses, setShowExpenses] = useState(false);
    const [showIncome, setShowIncome] = useState(false);
    const [estimated, setEstimated] = useState(0);
    const [expenses, setExpenses] = useState([]);
    const [income, setIncome] = useState([]);

    return {
        modal,
        estimated,
        expenses,
        income,
        showExpenses,
        showIncome,
        updateModal: setModal,
        updateEstimated: setEstimated,
        updateExpenses: setExpenses,
        updateIncome: setIncome,
        updateShowExpenses: setShowExpenses,
        updateShowIncome: setShowIncome
    }
}