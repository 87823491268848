/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Icon, Input } from 'semantic-ui-react'

/**
 * <SearchBox   data
 *              base
 *              placeholder
 *              searchText
 *              handleSearchResult
 *              handleResetFilter 
 *              handleSearchText />
 * 
 * @prop { array } data - array of documents
 * @prop { string } base - base key
 * @prop { string } placheholder - keyword for placeholder
 * @prop { string } searchText - current searched text
 * @prop { function } handleSearchResult - function to call upon finish search
 * @prop { function } handleResetFilter - function to reset filter upon clicking search button
 * @prop { function } handleSearchText - function to update search value
 */
function SearchBox(props) {
    const { data, base, placeholder, searchText } = props;

    /**
     * This function handles empty search field.
     */
    const handleEmptyField = () => {
        props.handleSearchResult(data);
        props.handleResetFilter();
    }

    useEffect(handleEmptyField, []);

    /**
     * This function handles search action.
     */
    const handleSearch = (value) => {
        if(value === '') {
            handleEmptyField();
        } else {
            let dataClone = data.filter(val => base in val && val[base].toLowerCase().includes(value.toLowerCase()));
            props.handleResetFilter();
            props.handleSearchResult(dataClone);
        }
        props.handleSearchText(value);
    }

    return (
        <Grid
            className='web-grid'
            verticalAlign='middle'
            textAlign='right'
            columns='equal'>
            <Grid.Row className='compact-element'>
                <Grid.Column stretched className='compact-element'>
                    <Input 
                        className='search-box'
                        iconPosition='left' 
                        placeholder={`Search document by ${placeholder}`}>
                        <Icon name='search' />
                        <input 
                            id='search-box' 
                            value={searchText} 
                            onChange={(event) => handleSearch(event.target.value)} />
                    </Input>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

SearchBox.propTypes = {
    data: PropTypes.array,
    base: PropTypes.string,
    placeholder: PropTypes.string,
    searchText: PropTypes.string,
    handleSearchResult: PropTypes.func,
    handleSearchText: PropTypes.func,
    handleResetFilter: PropTypes.func
}

export default SearchBox;