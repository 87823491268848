/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, Grid, Icon } from 'semantic-ui-react'

/* COMPONENTS AND ASSETS */
import FAQModal from './FAQModal'

/**
 * <FAQItem     faq
 *              faqKey
 *              faqIndex
 *              faqActiveIndex
 *              updateFaqActiveIndex
 *              handleUpdateFaq
 *              handleDeleteFaq />
 * 
 * @prop { object } faq - faq object
 * @prop { string } faqKey - object key
 * @prop { integer } faqIndex - index of faq
 * @prop { integer } faqActiveIndex - active faq index
 * @prop { function } updateFaqActiveIndex - function to update active index
 * @prop { function } handleUpdateFaq - function to update faq
 * @prop { function } handleDeleteFaq - function to delete faq
 */
function FAQItem(props) {
    const { faq, faqKey, faqIndex, faqActiveIndex } = props;

    return (
        <>
            <Accordion.Title 
                id={`faq-${faqIndex}`}
                index={faqIndex}
                active={faqActiveIndex === faqIndex}
                onClick={() => faqActiveIndex === faqIndex ? props.updateFaqActiveIndex(null) : props.updateFaqActiveIndex(faqIndex)}>
                <Icon name='dropdown' />
                {faq.question}
            </Accordion.Title>
            <Accordion.Content active={faqActiveIndex === faqIndex}>
                <Grid
                    className='web-grid'
                    relaxed
                    columns='equal'
                    verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column>
                            <span>{faq.answer}</span>
                        </Grid.Column>
                    </Grid.Row>
                    {localStorage.getItem('admin') === '1' ?
                        <Grid.Row className='compact-element'>
                            <Grid.Column />
                            <Grid.Column textAlign='right' width={1}>
                                <FAQModal
                                    type='edit'
                                    faq={faq}
                                    faqKey={faqKey}
                                    faqIndex={faqIndex}
                                    handleAction={props.handleUpdateFaq} />
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={1}>
                                <FAQModal
                                    type='delete'
                                    faq={faq}
                                    faqKey={faqKey}
                                    faqIndex={faqIndex}
                                    handleAction={props.handleDeleteFaq} />
                            </Grid.Column>
                        </Grid.Row>
                    : null}
                </Grid>
            </Accordion.Content>
        </>
    )
}

FAQItem.propTypes = {
    faq: PropTypes.object,
    faqKey: PropTypes.string,
    faqIndex: PropTypes.number,
    faqActiveIndex: PropTypes.number,
    updateFaqActiveIndex: PropTypes.func,
    handleUpdateFaq: PropTypes.func,
    handleDeleteFaq: PropTypes.func
}

export default FAQItem;