/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, Grid, Icon } from 'semantic-ui-react'

/* COMPONENTS AND ASSETS */
import PageDescriptionModal from './PageDescriptionModal'

/**
 * <PageDescriptionItem     pageDescription
 *                          pageDescriptionKey
 *                          pageDescriptionIndex
 *                          pageDescriptionActiveIndex
 *                          updatePageDescriptionActiveIndex
 *                          handleUpdatePageDescription
 *                          handleDeletePageDescription />
 * 
 * @prop { object } pageDescription - page description object
 * @prop { string } pageDescriptionKey - object key
 * @prop { integer } pageDescriptionIndex - index of page description
 * @prop { integer } pageDescriptionActiveIndex - active page description index
 * @prop { function } updatePageDescriptionActiveIndex - function to update active index
 * @prop { function } handleUpdatePageDescription - function to update page description
 * @prop { function } handleDeletePageDescription - function to delete page description
 */
function PageDescriptionItem(props) {
    const { pageDescription, pageDescriptionKey, pageDescriptionIndex, pageDescriptionActiveIndex } = props;
    return (
        <>
            <Accordion.Title 
                id={`page-description-${pageDescriptionIndex}`}
                index={pageDescriptionIndex}
                active={pageDescriptionActiveIndex === pageDescriptionIndex}
                onClick={() => pageDescriptionActiveIndex === pageDescriptionIndex ? props.updatePageDescriptionActiveIndex(null) : props.updatePageDescriptionActiveIndex(pageDescriptionIndex)}>
                <Icon name='dropdown' />
                {pageDescription.name}
            </Accordion.Title>
            <Accordion.Content active={pageDescriptionActiveIndex === pageDescriptionIndex}>
                <Grid
                    className='web-grid'
                    relaxed
                    columns='equal'
                    verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column>
                            <span>{pageDescription.description}</span>
                        </Grid.Column>
                    </Grid.Row>
                    {localStorage.getItem('admin') === '1' ?
                        <Grid.Row className='compact-element'>
                            <Grid.Column />
                            <Grid.Column textAlign='right' width={1}>
                                <PageDescriptionModal
                                    type='edit'
                                    pageDescription={pageDescription}
                                    pageDescriptionKey={pageDescriptionKey}
                                    pageDescriptionIndex={pageDescriptionIndex}
                                    handleAction={props.handleUpdatePageDescription} />
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={1}>
                                <PageDescriptionModal
                                    type='delete'
                                    pageDescription={pageDescription}
                                    pageDescriptionKey={pageDescriptionKey}
                                    pageDescriptionIndex={pageDescriptionIndex}
                                    handleAction={props.handleDeletePageDescription} />
                            </Grid.Column>
                        </Grid.Row>
                    : null}
                </Grid>
            </Accordion.Content>
        </>
    )
}

PageDescriptionItem.propTypes = {
    pageDescription: PropTypes.object,
    pageDescriptionKey: PropTypes.string,
    pageDescriptionIndex: PropTypes.number,
    pageDescriptionActiveIndex: PropTypes.number,
    updatePageDescriptionActiveIndex: PropTypes.func,
    handleUpdatePageDescription: PropTypes.func,
    handleDeletePageDescription: PropTypes.func
}

export default PageDescriptionItem;