/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import useDocumentItemState from '../states/useDocumentItemState'
import { Button, Grid, Item, Label, List, Modal, Responsive, Table } from 'semantic-ui-react'

/* EXTERNAL LIBRARIES */
import moment from 'moment'
import _ from 'lodash'

/**
 * <DocumentItem    headers
 *                  document
 *                  documentIndex />
 * 
 * @prop { array } headers - array of table headers
 * @prop { object } document - sheet row object
 * @prop { integer } documentIndex - index of document
 */
function DocumentItem(props) {
    const { headers, document, documentIndex } = props;
    const { 
        modal,
        updateModal,
    } = useDocumentItemState();

    return (
        <Modal
            className='web-modal'
            open={modal}
            onClose={() => updateModal(false)}
            closeOnDimmerClick={true}
            closeOnEscape={true}
            size='tiny'
            trigger={
                <List.Item 
                    id={`documents-${documentIndex}`} 
                    className='docu-list' 
                    onClick={() => updateModal(true)}>
                    <Grid
                        className='web-grid'
                        relaxed
                        columns='equal'>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column className='compact-element'>
                                <Item>
                                    <Item.Header as='h4'>{`${document['Title of Activity']}`}</Item.Header>
                                    <Responsive as={Item.Description} maxWidth={Responsive.onlyMobile.maxWidth}>
                                        {document['Type of Process']}
                                    </Responsive>
                                </Item>
                            </Grid.Column>
                            <Responsive 
                                as={Grid.Column} 
                                minWidth={Responsive.onlyTablet.minWidth}
                                textAlign='center'
                                className='compact-element'>
                                {document['Type of Process']}
                            </Responsive>
                            <Grid.Column 
                                textAlign='right' 
                                verticalAlign='top'
                                className='compact-element'>
                                <Label
                                    size='large'
                                    color={_.includes(['', undefined], document['Pended?']) && _.includes(['', undefined], document['Remarks']) ? 'grey' :
                                        document['Pended?'] !== '' ? 'red' :
                                        document['Remarks'] === 'Approved' ? 'green' : 'grey'}
                                    content={_.includes(['', undefined], document['Pended?']) && _.includes(['', undefined], document['Remarks']) ? 'Processing' :
                                        document['Pended?'] !== '' ? 'Pended' :
                                        document['Remarks'] === 'Approved' ? 'Approved' : 'Processing'} />
                                <br />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='compact-element'>
                            <Grid.Column className='compact-element' textAlign='right'>
                                <Item>
                                    <Item.Description>{`Submitted on 
                                        ${moment(document['Timestamp'], 'M/DD/YYYY HH:mm:ss').format('MMM D, YYYY')} 
                                        at ${moment(document['Timestamp'], 'M/DD/YYYY HH:mm:ss').format('HH:mm A')}`
                                    }</Item.Description>
                                </Item>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </List.Item>
            }>
            <Modal.Header className='modal-header'>{document['Title of Activity']}</Modal.Header>
            <Modal.Content scrolling>
                <Table celled size='small'>
                    <Table.Body>
                        {headers.length > 0 && headers.map((val, index) => (
                            <Table.Row key={index}>
                                <Table.Cell width='8'>{val}</Table.Cell>
                                <Table.Cell>{document['_rawData'][index] || ''}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    id='close-document-detail'
                    className='modal-buttons'
                    floated='right'
                    content='Close'
                    onClick={() => updateModal(false)} />
            </Modal.Actions>
        </Modal>
    )
}

DocumentItem.propTypes = {
    headers: PropTypes.array,
    document: PropTypes.object,
    documentIndex: PropTypes.number
}

export default DocumentItem;