/* REACT AND CSS */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Header, Icon, Responsive } from 'semantic-ui-react'

/**
 * <PageNotFound />
 */
function PageNotFound() {
    const history = useHistory();

    /**
     * This function redirects user back to homepage.
     */
    const backToHome = () => {
        history.push(`/${localStorage.getItem('session')}`);
        localStorage.setItem('activeMenu', '');
        window.location.reload();
    }

    return (
        <div className='login-container'>
            <Grid 
                className='login-grid' 
                relaxed 
                columns='equal'
                verticalAlign='middle'
                textAlign='center'>
                <Grid.Row>
                    <Responsive 
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                    <Grid.Column>
                        <Header 
                            as='h1' 
                            icon 
                            inverted>
                            <Icon name='warning sign' inverted />
                            404
                            <Header.Content>Page Not Found</Header.Content>
                            <Header.Subheader>The page you are looking for is not available.</Header.Subheader>
                        </Header>
                        <br /><br />
                        <Button
                            size='large'
                            content='Back to home'
                            onClick={backToHome} />
                    </Grid.Column>
                    <Responsive 
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default PageNotFound;