/* REACT AND CSS */
import React from 'react'
import { Grid, List, Responsive} from 'semantic-ui-react'
import PropTypes from 'prop-types' 

/* COMPONENTS AND ASSETS */
import UserModal from './UserModal'

/**
 * <UserItem            userKey
 *                      user
 *                      userIndex
 *                      handleUpdate
 *                      handleDelete />
 * 
 * @prop { string } userKey - object key
 * @prop { object } user - user object
 * @prop { integer } userIndex - index of user
 * @prop { function } handleUpdate - function to update a user
 * @prop { function } handleDelete - function to delete a user
 */

function UserItem(props) {
    const{ user, userKey, userIndex } = props;

    return(
        <List.Item>
            <Grid 
                className='web-grid'
                relaxed
                columns='equal'
                verticalAlign='top'>
                <Grid.Row>
                    <Grid.Column className='user-name-settings'>
                        <List.Header>{userKey}</List.Header>
                        <List.Description>{user.head}</List.Description>
                    </Grid.Column>
                    
                    {/* Tablet and Laptop */}
                    <Responsive
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        textAlign='right'
                        className='compact-element'
                        width={3}>
                        <UserModal
                            type='edit'
                            mobile={false}
                            user={user}
                            userKey={userKey}
                            userIndex={userIndex}
                            handleAction={props.handleUpdate} />
                    </Responsive>
                    <Responsive
                        as={Grid.Column} 
                        minWidth={Responsive.onlyTablet.minWidth}
                        className='compact-element'
                        textAlign='center'
                        width={3}>
                        <UserModal
                            type='delete'
                            mobile={false}
                            user={user}
                            userKey={userKey}
                            userIndex={userIndex}
                            handleAction={props.handleDelete} />                            
                    </Responsive>
                    
                    {/* Mobile */}
                    <Responsive
                        as={Grid.Column}
                        maxWidth={Responsive.onlyMobile.maxWidth}
                        textAlign='right'
                        className='compact-element'
                        width={2}>
                        <UserModal
                            type='edit'
                            mobile={true}
                            user={user}
                            userKey={userKey}
                            userIndex={userIndex}
                            handleAction={props.handleUpdate} />
                    </Responsive>
                    <Responsive
                        as={Grid.Column}
                        maxWidth={Responsive.onlyMobile.maxWidth}
                        width={2}>
                        <UserModal
                            type='delete'
                            mobile={true}
                            user={user}
                            userKey={userKey}
                            userIndex={userIndex}
                            handleAction={props.handleDelete} />
                    </Responsive>
                </Grid.Row> 
            </Grid>
        </List.Item>        
    )
}

UserItem.propTypes = {
    userKey: PropTypes.string,
    user: PropTypes.object,
    userIndex: PropTypes.number,
    handleUpdate: PropTypes.func,
    handleDelete: PropTypes.func
}

export default UserItem;