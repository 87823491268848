import { useState } from 'react'

export default function useDocumentTrackerState() {
    const [headers, setHeaders] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [baseKey, setBaseKey] = useState('Title of Activity');
    const [filter, setFilter] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    return {
        headers,
        searchResult,
        searchText,
        baseKey,
        filter,
        activePage,
        totalPages,
        updateHeaders: setHeaders,
        updateSearchResult: setSearchResult,
        updateSearchText: setSearchText,
        updateBaseKey: setBaseKey,
        updateFilter: setFilter,
        updateActivePage: setActivePage,
        updateTotalPages: setTotalPages        
    }
}