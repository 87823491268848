import { useState } from 'react'

export default function useHelpState() {
    const [faqActiveIndex, setFaqActiveIndex] = useState(null);
    const [pageDescriptionActiveIndex, setPageDescriptionActiveIndex] = useState(null);

    return {
        faqActiveIndex,
        pageDescriptionActiveIndex,
        updateFaqActiveIndex: setFaqActiveIndex,
        updatePageDescriptionActiveIndex: setPageDescriptionActiveIndex
    }
}