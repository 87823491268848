/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useEditAdminEmail from '../states/useEditAdminEmailState'
import { Button, Form, Input, Message, Modal, Segment } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../utils/API'

/**
 * <EditAdminEmail  user
 *                  visible
 *                  updateLoading
 *                  updateCurrentUser
 *                  updateVisible
 *                  handleLogout />
 * 
 * @prop { object } user - user object
 * @prop { boolean } visible - modal visibility
 * @prop { function } updateLoading - function to update loading state
 * @prop { function } updateCurrentUser - function to update current user
 * @prop { function } updateVisible - function to update modal visibility
 * @prop { function } handleLogout -  function to log out user after changing admin email address
 */
function EditAdminEmail(props) {
    const { visible, user } = props;
    const {
        loading,
        step,
        current,
        newEmail,
        code,
        OTP,
        updateLoading,
        updateStep,
        updateCurrent,
        updateNewEmail,
        updateCode,
        updateOTP
    } = useEditAdminEmail();

    /**
     * This function sets initial data.
     */
    const setData = () => {
        if(visible) {
            updateCurrent(user.head);
            updateStep(0);
            updateLoading(false);
            updateCode('');
        }
    }

    useEffect(setData, [user, visible])

    /**
     * This function verifies the new email.
     */
    const handleVerifyEmail = () => {
        let check = true;
        if(Array.isArray(user.email)) {
            if(_.includes(user.email, newEmail)) {
                NotificationManager.error('Email is already used as a shared email.', 'Error', 2000);
                check = false;
            }
        } else {
            if(user.email === newEmail) {
                NotificationManager.error('Email is already used as a shared email.', 'Error', 2000);
                check = false;
            }
        }
        if(check) {
            updateLoading(true);
            let data = new URLSearchParams({
                email: newEmail,
                organization: localStorage.getItem('session')
            })
            API.validateAdminEmail(data, (response) => {
                updateOTP(response.data);
                NotificationManager.success(response.message, 'Success', 2000);
                updateStep(1);
                updateLoading(false);
            })
        }
    }

    /**
     * This function verifies the verification code.
     */
    const handleVerifyCode = () => {
        updateLoading(true);
        if(OTP === code) {
            let newUser = {...user};
            delete newUser.username;
            newUser.head = newEmail;
            props.updateLoading(true);
            let data = new URLSearchParams({
                previous: user.username,
                username: user.username,
                user: JSON.stringify(newUser),
            });
            props.updateVisible(false);
            API.users.update(data, (response) => {
                NotificationManager.success(response.message, 'Success', 2000);
                setTimeout(() => {
                    NotificationManager.info('Logged out due to changed in admin email address!', '', 2000);
                }, 1000);
                props.handleLogout();
                props.updateLoading(false);
            })
        } else {
            NotificationManager.error('Invalid verification code!', 'Error', 2000);
            updateLoading(false);
        }
    }

    /**
     * This function handles hiding email.
     */
    const hideEmail = () => {
        let email = newEmail;
        let hidden = '';
        for(var i = 0; i < email.length; i++) {
            if(email[i] !== '@' && i > 1) {
                hidden += '*';
            } else {
                hidden += email[i];
            }
        }
        return hidden;
    }

    return (
        <Modal
            className='web-modal'
            open={visible}
            onClose={() => props.updateVisible(false)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size='tiny' >
            <Modal.Header className='modal-header'>Change Admin Email Address</Modal.Header>
            <Modal.Content>
                <Segment
                    as={Form}
                    loading={loading}
                    onSubmit={step === 0 ? handleVerifyEmail : handleVerifyCode}>
                    {step === 0 ?
                        <>
                            <Form.Field 
                                id={'current-admin-email'}
                                required
                                control={Input}
                                type='email'
                                label='Current Admin Email Address'
                                placeholder='Email Address'
                                value={current}
                                onChange={(event, { value }) => updateCurrent(value)} />
                            <Form.Field 
                                id={'new-admin-email'}
                                required
                                control={Input}
                                type='email'
                                label='New Admin Email Address'
                                placeholder='Email Address'
                                value={newEmail}
                                onChange={(event, { value }) => updateNewEmail(value)} />
                        </>
                    :
                        <>
                            <Message>
                                We've sent a verification code to <strong>{hideEmail()}</strong>.
                                Please check your email and input the verification code below.
                            </Message>
                            <Form.Field 
                                id={'OTP-code'}
                                required
                                control={Input}
                                type='text'
                                label='Verification Code'
                                value={code}
                                onChange={(event, { value }) => updateCode(value)} />
                        </>
                    }
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type='button'
                    id='update-admin-email'
                    className='modal-buttons'
                    floated='right'
                    disabled={current === newEmail}
                    content={step === 0 ? 'Send Verification Code' : 'Update'}
                    onClick={step === 0 ? handleVerifyEmail : handleVerifyCode} />
                <Button
                    type='button'
                    id='cancel-update-admin-email'
                    floated='right'
                    content='Cancel'
                    onClick={() => props.updateVisible(false)} />
            </Modal.Actions>
        </Modal>
    )
}

EditAdminEmail.propTypes = {
    user: PropTypes.object,
    visible: PropTypes.bool,
    updateLoading: PropTypes.func,
    updateCurrentUser: PropTypes.func,
    updateVisible: PropTypes.func,
    handleLogout: PropTypes.func
}

export default EditAdminEmail;

