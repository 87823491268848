import firebase from 'firebase/app'
import 'firebase/auth'
const production = true;

const config = {
    apiKey: production ? "AIzaSyDd-mFgK-SiB5h_v08PEBCMJ1X_ytOCPOc" : "AIzaSyA6LPsi8uYP0_aOGnNkNVRGy2ayedt-TdY",
    authDomain: production ? "cso-finance-website.firebaseapp.com" : "prosdev-cso-fin-dev.firebaseapp.com",
    databaseURL: production ? "https://cso-finance-website.firebaseio.com" : "https://prosdev-cso-fin-dev.firebaseio.com",
    projectId: production ? "cso-finance-website" : "prosdev-cso-fin-dev",
    storageBucket: production ? "cso-finance-website.appspot.com" : "prosdev-cso-fin-dev.appspot.com",
}

firebase.initializeApp(config);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;