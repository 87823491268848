/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid, Icon, Popup, Responsive } from 'semantic-ui-react'
import LinesEllipsis from 'react-lines-ellipsis'

/* COMPONENTS AND ASSETS */
import LinkCardModal from './LinkCardModal'

/**
 * <LinkCard    cardKey
 *              card
 *              cardIndex
 *              handleUpdate
 *              handleDelete
 *              fluid />
 * 
 * @prop { string } cardKey - object key
 * @prop { object } card - card object
 * @prop { integer } cardIndex - index of card
 * @prop { function } handleUpdate - function to update a card
 * @prop { function } handleDelete - function to delete a card
 * @prop { boolean } fluid - configure card size
 */
function LinkCard(props) {
    const { cardKey, card, cardIndex, fluid } = props;

    /**
     * This function opens the link in the new tab.
     */
    const handleClick = () => {
        window.open(card.link, '_blank');
    }

    return (
        <Card 
            id={`open-link-card-${cardIndex}`}
            className='link-card'
            fluid={fluid}
            onClick={localStorage.getItem('admin') === '1' ? () => {} : handleClick}>
            <Grid
                className='web-grid'
                relaxed
                columns='equal'
                verticalAlign='middle'>
                {localStorage.getItem('admin') === '0' ?
                    <Grid.Row className='space-grid' />
                : null}
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <Card.Header>{card.cardTitle}</Card.Header> 
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='compact-element'>
                    <Grid.Column>
                        <Divider className='compact-element' section />
                    </Grid.Column>
                </Grid.Row>
                <Responsive 
                    as={Grid.Row} 
                    maxWidth={Responsive.onlyComputer.minWidth}
                    className='card-desc'>
                    <Grid.Column textAlign='center'>
                        <Card.Description>{card.cardDesc}</Card.Description>
                    </Grid.Column>
                </Responsive>
                <Responsive
                    as={Grid.Row}
                    minWidth={Responsive.onlyComputer.minWidth}
                    className='card-desc'>
                    <Grid.Column textAlign='center'>
                        <Popup
                            className='popup-description'
                            trigger={
                                <Card.Description>
                                    <LinesEllipsis
                                        text={card.cardDesc}
                                        maxLine='3'
                                        ellipsis='...'
                                        trimRight
                                        basedOn='letters'/>
                                </Card.Description>
                            }
                            content={card.cardDesc}
                            flowing
                            position='top center'
                            basic
                        />
                    </Grid.Column>
                </Responsive>
            </Grid>
            {localStorage.getItem('admin') === '1' ?
                <div className='card-buttons-wrapper'>
                    <Popup
                        trigger={
                            <Icon
                                id={`open-link-icon-${cardIndex}`}
                                link
                                size='large'
                                className='card-buttons'
                                name='external alternate'
                                onClick={handleClick} />
                        }
                        position='bottom center'
                        content='Open' />
                    <LinkCardModal 
                        type='edit' 
                        cardKey={cardKey}
                        card={card}
                        cardIndex={cardIndex}
                        handleAction={props.handleUpdate} />
                    <LinkCardModal
                        type='delete' 
                        cardKey={cardKey}
                        card={card}
                        cardIndex={cardIndex}
                        handleAction={props.handleDelete} />
                </div>
            : null}
        </Card>
    )
}

LinkCard.propTypes = {
    cardKey: PropTypes.string,
    card: PropTypes.object,
    cardIndex: PropTypes.number,
    handleUpdate: PropTypes.func,
    handleDelete: PropTypes.func,
    fluid: PropTypes.bool
}

export default LinkCard;