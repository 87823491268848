/* REACT AND CSS */
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Dropdown, Grid, Header, Image, Responsive, Segment } from 'semantic-ui-react'
import useLoginState from '../states/useLoginState'
import { NotificationManager } from 'react-notifications'
import './login.css'

/* EXTERNAL LIBRARIES */
import { API } from '../utils/API'
import { googleProvider, firebaseAuth } from '../utils/firebase'

/* COMPONENTS AND ASSETS */
import Title from '../components/Title'
import Redirect from '../components/Redirect'
import logo from '../assets/logo.png'
import tagline from '../assets/tagline.png'

/**
 * <Login />
 */
function Login() {
    const history = useHistory();
    const {
        organizations,
        logged,
        redirect,
        selectedOrg,
        updateLoading,
        updateLogged,
        updateRedirect,
        updateSelectedOrg,
        updateOrganizations
    } = useLoginState();
    const email = useRef('');
    const token = useRef('');
    const selected = useRef('');

    /**
     * This function resets login credentials.
     */
    const initial = () => {
        window.onload = () => {
            localStorage.setItem('message', '');
        }
        firebaseAuth().getRedirectResult().then((result) => {
            if(result.credential) {
                updateRedirect(true);
                let user = result.user;
                let credential = result.credential;
                let auth = new URLSearchParams({
                    email: user.email,
                    token: credential.idToken
                })
                API.getOrganization(auth, (response) => {
                    if(response.success) {
                        email.current = user.email;
                        token.current = credential.idToken;
                        if(response.data.length > 1) {
                            updateOrganizations(response.data.map((val, key) => {
                                return {
                                    key,
                                    value: val,
                                    text: val
                                }
                            }))
                            updateLogged(true);
                            updateRedirect(false);
                        } else {
                            selected.current = response.data[0];
                            handleProceed();
                        }
                    } else {
                        user.delete();
                        NotificationManager.error(response.message, 'Login Failed', 2000);
                        updateRedirect(false);
                    }
                    updateLoading(false);
                })
            } else {
                API.logout(() => {
                    firebaseAuth().signOut();
                    localStorage.setItem('message', '');
                    localStorage.setItem('session', '');
                    updateRedirect(false);
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(initial, [])

    /**
     * This function handles login function.
     */
    const handleLogin = () => {
        updateLoading(true);
        firebaseAuth().signInWithRedirect(googleProvider);
        localStorage.setItem('message', 'Processing your request...');
    }

    /**
     * This function proceed to the next page.
     */
    const handleProceed = () => {
        updateLoading(true);
        let data = new URLSearchParams({ 
            organization: selected.current, 
            email: email.current, 
            token: token.current 
        });
        API.login(data, (response) => {
            if(response.success) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('email', email.current);
                localStorage.setItem('session', response.data.username);
                localStorage.setItem('admin', response.data.admin);
                localStorage.setItem('activeMenu', '');
                NotificationManager.success(response.message, '', 2000);
                history.push(`${response.data.username}`);
            }
        })
    }

    return (
        redirect ? <Redirect /> :
        <>
            <Title title='Login' />
            <div className='login-container'>
                <Grid 
                    className='login-grid' 
                    relaxed 
                    columns='equal'
                    verticalAlign='middle'
                    textAlign='center'>
                    <Grid.Row>
                        <Responsive as={Grid.Column} minWidth={Responsive.onlyTablet.minWidth}>
                            <Image 
                                src={tagline} 
                                size='big' 
                                centered />
                        </Responsive>
                        <Grid.Column>
                            <Image 
                                className='login-logo'
                                centered
                                src={logo}
                                size='small' />
                            <br />
                            <Segment
                                className='login-segment' 
                                textAlign='center'
                                raised
                                padded='very'>
                                {logged ?
                                    <Grid
                                        className='web-grid'
                                        relaxed
                                        columns='equal'
                                        verticalAlign='middle'
                                        textAlign='center'>
                                        <Grid.Row className='compact-element'>
                                            <Grid.Column className='compact-element'>
                                                <Header 
                                                    as='h5' 
                                                    textAlign='center'
                                                    className='login-message' 
                                                    content='You seem to have multiple organizations linked to this email.' />
                                                <Header
                                                    as='h4'
                                                    textAlign='center'
                                                    className='login-message'
                                                    content={email.current} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column className='compact-element'>
                                                <Dropdown 
                                                    id='choose-org'
                                                    className='choose-org'
                                                    selectOnBlur={false}
                                                    selectOnNavigation={false}
                                                    selection
                                                    fluid
                                                    placeholder='Choose an organization'
                                                    options={organizations}
                                                    value={selectedOrg}
                                                    onChange={(event, { value }) => {
                                                        updateSelectedOrg(value);
                                                        selected.current = value;
                                                    }} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className='compact-element'>
                                            <Grid.Column>
                                                <Button
                                                    id='proceed-button'
                                                    className='proceed-button'
                                                    fluid
                                                    content='Proceed'
                                                    disabled={selectedOrg === ''}
                                                    color='green'
                                                    onClick={handleProceed} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                :
                                    <>
                                        <Responsive 
                                            as={Button}
                                            maxWidth={Responsive.onlyTablet.maxWidth}
                                            id='login-button'
                                            className='login-button'
                                            content='Login with Google'
                                            icon='google'
                                            size='large'
                                            fluid
                                            onClick={handleLogin} />
                                        <Responsive 
                                            as={Button}
                                            minWidth={Responsive.onlyComputer.minWidth}
                                            id='login-button'
                                            className='login-button'
                                            content='Login with Google'
                                            icon='google'
                                            size='large'
                                            onClick={handleLogin} />
                                    </>
                                }
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    )
}

export default Login;