/* REACT AND CSS */
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Link, Route, Switch, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import usePageState from '../../states/usePageState'
import { Dimmer, Dropdown, Grid, Header, Icon, Image, Loader, Menu, Responsive, Segment, Sidebar } from 'semantic-ui-react'
import { NotificationManager} from 'react-notifications'
import './account.css'
import routes from './path'

/* EXTERNAL LIBRARIES */
import { API } from '../../utils/API'
import { firebaseAuth } from '../../utils/firebase'

/* COMPONENTS AND ASSETS */
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import BalanceModal from '../../components/BalanceModal'
import logo from '../../assets/fin-logo.png'
import EditProfile from '../../components/EditProfile'
import EditAdminEmail from '../../components/EditAdminEmail'

/**
 * <Landing />
 */
function Landing() {
    let { path } = useRouteMatch();
    let params = useParams();
    let history = useHistory();
    const {
        loading,
        showSidebar,
        showMenu,
        showEditProfile,
        showEditEmail,
        fetchBalance,
        currentUser,
        cards,
        announcements,
        documents,
        spf,
        balance,
        debts,
        faqs,
        pageDescriptions,
        users,
        updateLoading,
        updateShowSidebar,
        updateShowMenu,
        updateShowEditProfile,
        updateShowEditEmail,
        updateFetchBalance,
        updateCurrentUser,
        updateCards,
        updateAnnouncements,
        updateDocuments,
        updateSpf,
        updateBalance,
        updateDebts,
        updateFaqs,
        updatePageDescriptions,
        updateUsers
    } = usePageState();

    /**
     * This function validates current user.
     */
    const validateUser = () => {
        let data = new URLSearchParams({
            org: params.name,
            token: localStorage.getItem('token')
        })
        API.validateUser(data, (response) => {
            if(response.success) {
                localStorage.setItem('session', response.data.user.username || '');
                localStorage.setItem('admin', response.data.user.admin);
                updateCurrentUser(response.data.user);
            } else {
                NotificationManager.info(response.message);
                handleLogOut();
            }
        })
    }

    useEffect(validateUser, []);

    /**
     * This function calls back-end and fetch data upon rendering.
     */
    const fetchData = () => {
        updateLoading(true);
        let auth = new URLSearchParams({
            token: localStorage.getItem('token')
        })
        API.users.get(auth, (response) => {
            updateUsers(response.data.users);
        })
        API.getAllDocuments(auth, (response) => {
            if(response.data.documents && response.data.documents.length > 0) {
                let data = JSON.parse(response.data.documents);
                updateDocuments(data || []);
                updateLoading(false);
            }
        }) 
    }

    useEffect(fetchData, []);

    const pages = routes.map(route => {
        let Component = route.component;
        return (
            <Route
                key={route.key}
                exact={route.exact}
                path={`${path}${route.path}`}
                render={() => 
                    <Component 
                        loading={loading}
                        cards={cards}
                        announcements={announcements}
                        documents={documents}
                        faqs={faqs}
                        pageDescriptions={pageDescriptions}
                        users={users}
                        debts={debts}
                        SPFs={spf}
                        updateCards={updateCards}
                        updateAnnouncements={updateAnnouncements}
                        updateDocuments={updateDocuments}
                        updateFaqs={updateFaqs}
                        updatePageDescriptions={updatePageDescriptions}
                        updateUsers={updateUsers}
                        updateDebts={updateDebts}
                        updateSPFs={updateSpf}
                        updateLoading={updateLoading}
                        handleLogout={handleLogOut} />
                }
                {...routes}
            />
        )
    });

    /**
     * This function handles logging out user.
     */
    const handleLogOut = () => {
        updateShowMenu(false);
        API.logout(() => {
            firebaseAuth().signOut();
            localStorage.setItem('message', '');
            localStorage.setItem('email', '');
            localStorage.setItem('session', '');
            localStorage.setItem('token', '');
            history.push('/');
        })
    }

    /**
     * This function handles changing tab on the navigation bar.
     * @param { event } event - onClick event
     * @param { string } name - name of the selected tab
     */
    const handleChangeTab = (event, {name}) => {
        if(localStorage.getItem('activeMenu') !== name) {
            localStorage.setItem('activeMenu', name);
        }
        updateShowSidebar(false);
    }

    return (
        <Router>
            <Title title={localStorage.getItem('session')} />
            <Sidebar.Pushable as={Segment} className='content-pusher'>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    direction='left'
                    vertical
                    inverted
                    visible={showSidebar}
                    className='sidebar-menu'
                    onHide={() => updateShowSidebar(false)}
                    width='thin'>
                    <Menu.Item>
                        <Image 
                            src={logo} 
                            size='tiny'
                            centered
                            className='fin-logo' />
                    </Menu.Item>
                    <Menu.Item
                        id='home-mobile'
                        name=''
                        as={Link}
                        to={{ pathname: `/${localStorage.getItem('session')}` }}
                        active={localStorage.getItem('activeMenu') === ''}
                        onClick={handleChangeTab}>
                        <Icon name='home' />
                        Home
                    </Menu.Item>
                    <Menu.Item
                        id='document-tracker-mobile'
                        name='document-tracker'
                        as={Link}
                        to={{ pathname: `/${localStorage.getItem('session')}/document-tracker` }}
                        active={localStorage.getItem('activeMenu') === 'document-tracker'}
                        onClick={handleChangeTab}>
                        <Icon name='file alternate' />
                        Documents
                    </Menu.Item>
                    <Menu.Item
                        id='spf-tracker-mobile'
                        name='spf-tracker'
                        as={Link}
                        to={{ pathname: `/${localStorage.getItem('session')}/spf-tracker` }}
                        active={localStorage.getItem('activeMenu') === 'spf-tracker'}
                        onClick={handleChangeTab}>
                        <Icon name='shopping cart' />
                        Procurements
                    </Menu.Item>
                    <Menu.Item
                        id='debts-mobile'
                        name='debts'
                        as={Link}
                        to={{ pathname: `/${localStorage.getItem('session')}/debts` }}
                        active={localStorage.getItem('activeMenu') === 'debts'}
                        onClick={handleChangeTab}>
                        <Icon name='percent' />
                        Debts
                    </Menu.Item>
                    <Menu.Item
                        id='help-mobile'
                        name='help'
                        as={Link}
                        to={{ pathname: `/${localStorage.getItem('session')}/help` }}
                        active={localStorage.getItem('activeMenu') === 'help'}
                        onClick={handleChangeTab}>
                        <Icon name='question circle' />
                        Help
                    </Menu.Item>
                    {localStorage.getItem('admin') === '1' ?
                        <Menu.Item
                            id='settings-mobile'
                            name='settings'
                            as={Link}
                            to={{ pathname: `/${localStorage.getItem('session')}/settings` }}
                            active={localStorage.getItem('activeMenu') === 'settings'}
                            onClick={handleChangeTab}>
                            <Icon name='settings' />
                            Settings
                        </Menu.Item>
                    : null}
                </Sidebar>
                <Sidebar.Pusher dimmed={showSidebar}>
                    <Segment 
                        basic 
                        className='page-segment' 
                        attached='top'>
                        <Menu 
                            inverted 
                            borderless  
                            className='user-menu'
                            fixed='top'>
                            <Responsive 
                                as={Menu.Menu} 
                                minWidth={Responsive.onlyComputer.minWidth}
                                position='left' 
                                secondary='true'>
                                <Menu.Item>
                                    <Image 
                                        src={logo} 
                                        size='mini'
                                        className='fin-logo' />
                                </Menu.Item>
                                <Menu.Item
                                    id='home'
                                    name=''
                                    as={Link}
                                    to={{ pathname: `/${localStorage.getItem('session')}` }}
                                    active={localStorage.getItem('activeMenu') === ''}
                                    onClick={handleChangeTab}>
                                    <Header 
                                        as='h5'
                                        content='Home' 
                                        className='menu-header' />
                                </Menu.Item>
                                <Menu.Item
                                    id='document-tracker'
                                    name='document-tracker'
                                    as={Link}
                                    to={{ pathname: `/${localStorage.getItem('session')}/document-tracker` }}
                                    active={localStorage.getItem('activeMenu') === 'document-tracker'}
                                    onClick={handleChangeTab}>
                                    <Header 
                                        as='h5' 
                                        content='Documents' 
                                        className='menu-header' />
                                </Menu.Item>
                                <Menu.Item
                                    id='spf-tracker'
                                    name='spf-tracker'
                                    as={Link}
                                    to={{ pathname: `/${localStorage.getItem('session')}/spf-tracker` }}
                                    active={localStorage.getItem('activeMenu') === 'spf-tracker'}
                                    onClick={handleChangeTab}>
                                    <Header 
                                        as='h5' 
                                        content='Procurements' 
                                        className='menu-header' />
                                </Menu.Item>
                                <Menu.Item
                                    id='debts'
                                    name='debts'
                                    as={Link}
                                    to={{ pathname: `/${localStorage.getItem('session')}/debts` }}
                                    active={localStorage.getItem('activeMenu') === 'debts'}
                                    onClick={handleChangeTab}>
                                    <Header 
                                        as='h5' 
                                        content='Debts' 
                                        className='menu-header' />
                                </Menu.Item>
                                <Menu.Item
                                    id='help'
                                    name='help'
                                    as={Link}
                                    to={{ pathname: `/${localStorage.getItem('session')}/help` }}
                                    active={localStorage.getItem('activeMenu') === 'help'}
                                    onClick={handleChangeTab}>
                                    <Header 
                                        as='h5' 
                                        content='Help' 
                                        className='menu-header' />
                                </Menu.Item>
                                {localStorage.getItem('admin') === '1' ?
                                    <Menu.Item
                                        id='settings'
                                        name='settings'
                                        as={Link}
                                        to={{ pathname: `/${localStorage.getItem('session')}/settings` }}
                                        active={localStorage.getItem('activeMenu') === 'settings'}
                                        onClick={handleChangeTab}>
                                        <Header 
                                            as='h5' 
                                            content='Settings' 
                                            className='menu-header' />
                                    </Menu.Item>
                                : null}
                            </Responsive>
                            <Responsive
                                as={Menu.Menu}
                                maxWidth={Responsive.onlyTablet.maxWidth}
                                position='left'>
                                <Grid className='web-grid' verticalAlign='middle'>
                                    <Grid.Column className='compact-element'>
                                        <Icon 
                                            id='menu-mobile'
                                            link
                                            size='big' 
                                            name='bars' 
                                            className='icons'
                                            onClick={() => updateShowSidebar(true)} />
                                    </Grid.Column>
                                </Grid>
                            </Responsive>
                            <Menu.Menu position='right'>
                                <Menu.Item className='welcome-text' onClick={() => updateShowMenu(!showMenu)}>
                                    <Header 
                                        as='h5' 
                                        className='menu-header'
                                        content={`Welcome back, ${localStorage.getItem('session')}!`} />
                                </Menu.Item>
                                <Grid 
                                    relaxed
                                    columns='equal'
                                    className='menu-grid' 
                                    verticalAlign='middle'>
                                    <Grid.Row>
                                        <Grid.Column className='compact-element'>
                                            <Dropdown
                                                open={showMenu}
                                                icon={
                                                    <Icon 
                                                        id='profile'
                                                        size='big' 
                                                        name='user circle' 
                                                        className='icons'
                                                        onClick={() => updateShowMenu(!showMenu)} />
                                                }>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item 
                                                        id='edit-profile'
                                                        icon={localStorage.getItem('admin') === '1' ? 'edit' : 'user'} 
                                                        text={localStorage.getItem('admin') === '1' ? 'Edit Profile' : 'View Profile'} 
                                                        onClick={() => {
                                                            updateShowEditProfile(true);
                                                            updateShowMenu(!showMenu);
                                                        }} />
                                                    <BalanceModal 
                                                        documents={documents}
                                                        balance={balance} 
                                                        fetchBalance={fetchBalance}
                                                        updateBalance={updateBalance} 
                                                        updateFetchBalance={updateFetchBalance}
                                                        updateShowMenu={updateShowMenu} />
                                                    {localStorage.getItem('admin') === '1' ?
                                                        <Dropdown.Item 
                                                            id='edit-admin-email'
                                                            icon='shield alternate' 
                                                            text='Change Admin Email Address' 
                                                            onClick={() => {
                                                                updateShowEditEmail(true);
                                                                updateShowMenu(!showMenu);
                                                            }} />
                                                    : null}
                                                    <Dropdown.Item 
                                                        id='sign-out'
                                                        icon='sign out' 
                                                        text='Logout'
                                                        onClick={handleLogOut} />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Menu.Menu>
                        </Menu>
                        <Dimmer.Dimmable 
                            className='dimmer-content'
                            blurring 
                            dimmed={loading}>
                            <Switch>{pages}</Switch>
                        </Dimmer.Dimmable>
                        <Loader content='Loading' active={loading} />
                        {!loading ?
                            <Footer />
                        : null}
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            <EditProfile 
                visible={showEditProfile}
                user={currentUser}
                updateLoading={updateLoading}
                updateCurrentUser={updateCurrentUser}
                updateVisible={updateShowEditProfile} />
            <EditAdminEmail
                visible={showEditEmail}
                user={currentUser}
                updateLoading={updateLoading}
                updateCurrentUser={updateCurrentUser}
                updateVisible={updateShowEditEmail}
                handleLogout={handleLogOut} />
        </Router>
    )
}

export default Landing;