/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import usePageDescriptionModalState from '../states/usePageDescriptionModalState'
import { Button, Form, Icon, Input, Modal, TextArea } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/**
 * <FAQPageDescriptionModal   type
 *                            pagedescription [OPTIONAL]
 *                            pagedescriptionKey [OPTIONAL]
 *                            pagedescriptionIndex [OPTIONAL]
 *                            handleAction />
 * 
 * @prop { string } type - action type
 * @prop { object } pageDescription - pagedescription object
 * @prop { string } pageDescriptionKey - object key
 * @prop { integer } pageDescriptionIndex - index of pagedescription
 * @prop { function } handleAction - function to manipulate pagedescription
 */
function PageDescriptionModal(props) {
    const { type, pageDescription, pageDescriptionKey, pageDescriptionIndex } = props;
    const {
        formState,
        modal,
        updateFormState,
        updateEditModal,
        updateDeleteModal,
        resetModal,
        resetForm
    } = usePageDescriptionModalState();

    /**
     * Initialize data upon editing data.
     */
    const setData = () => {
        if(modal.edit || modal.delete) {
            if(type === 'edit') {
                updateFormState(pageDescription.name, pageDescription.description);
            } else {
                resetForm();
            }
        }
    }

    useEffect(setData, [pageDescription, type, modal]);

    /**
     * This function handles creating the Page Description.
     */
    const handleCreate = () => {
        props.handleAction(formState);
        resetModal();
    }

    /**
     * This function handles editing the Page Description.
     */
    const handleEdit = () => {
        if (formState.name.trim() === pageDescription.name.trim() &&
            formState.description.trim() === pageDescription.description.trim()) {
            NotificationManager.info('No changes made.', '', 2000);
        } else {
            props.handleAction(pageDescriptionKey, formState);
        }
        resetModal();
    }

    /**
     * This function handles deleting the Page Description.
     */
    const handleDelete = () => {
        props.handleAction(pageDescriptionKey);
        resetModal();
    }

    /**
     * This function handles changing values of the fields.
     * @param { event } event - onChange event
     * @param { string } name - name of the component
     * @param { string } value - new value of the field
     */
    const handleChange = (event, { name, value }) => {
        let formStateClone = {...formState};
        formStateClone[name] = value;
        updateFormState(formStateClone.name, formStateClone.description);
    }

    return (
        type === 'create' || type === 'edit' ? 
            <Modal
                open={modal.edit}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                size='tiny'
                className='web-modal'
                trigger={
                    type === 'create' ?
                        <Button
                            id='create-page-description'
                            className='page-buttons'
                            content='Add'
                            floated='right'
                            onClick={() => updateEditModal(true)} />
                    :
                        <Icon
                            id={`edit-page-description-${pageDescriptionIndex}`}
                            link
                            size='small'
                            name='pencil'
                            onClick={() => updateEditModal(true)} />
                }>
                <Modal.Header className='modal-header'>{type === 'create' ? 'Create Page Description' : 'Edit Page Description'}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={type === 'create' ? handleCreate : handleEdit}>
                        <Form.Field 
                            required
                            id='page-description-name'
                            control={Input}
                            type='text'
                            label='Page Name'
                            placeholder='Name'
                            name='name'
                            value={formState.name}
                            onChange={handleChange} />
                        <Form.Field 
                            required
                            id='page-description-content'
                            control={TextArea}
                            type='text'
                            label='Page Description'
                            placeholder='Description'
                            name='description'
                            value={formState.description}
                            onChange={handleChange} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        type='submit'
                        id='update-page-description-button'
                        className='modal-buttons'
                        floated='right'
                        disabled={formState.name.trim() === '' || formState.description.trim() === ''}
                        content={type === 'create' ? 'Create' : 'Update'}
                        onClick={type === 'create' ? handleCreate : handleEdit} />
                    <Button 
                        type='button'
                        id='cancel-update-page-description-button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
        :
            <Modal
                open={modal.delete}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                className='web-modal'
                size='mini'
                trigger={
                    <Icon
                        id={`delete-page-description-${pageDescriptionIndex}`}
                        link
                        size='small'
                        name='trash'
                        onClick={() => updateDeleteModal(true)} />
                }>
                <Modal.Header className='modal-header'>Delete Page Description</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete <strong>{pageDescription.name}</strong>?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        type='button'
                        id='delete-page-description-button'
                        className='modal-buttons'
                        floated='right'
                        content='Delete'
                        onClick={handleDelete} />
                    <Button 
                        type='button'
                        id='cancel-delete-page-description-button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
    )
}

PageDescriptionModal.propTypes = {
    type: PropTypes.string,
    pageDescriptionKey: PropTypes.string,
    pageDescription: PropTypes.object,
    pageDescriptionIndex: PropTypes.number,
    handleAction: PropTypes.func
}

export default PageDescriptionModal;