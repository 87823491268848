/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useLinkCardModalState from '../states/useLinkCardModalState'
import { Button, Card, Form, Grid, Header, Icon, Input, Modal, TextArea } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/**
 * <LinkCardModal   type
 *                  cardKey [OPTIONAL]
 *                  card [OPTIONAL]
 *                  cardIndex [OPTIONAL]
 *                  handleAction />
 * 
 * @prop { string } type - action type
 * @prop { string } cardKey - object key
 * @prop { object } card - card object
 * @prop { integer } cardIndex - index of card
 * @prop { function } handleAction - function to manipulate card
 */
function LinkCardModal(props) {
    const { type, card, cardKey, cardIndex } = props;
    const {
        formState,
        modal,
        updateFormState,
        updateEditModal,
        updateDeleteModal,
        resetModal,
        resetForm
    } = useLinkCardModalState();

    /**
     * Initialize data upon editing data.
     */
    const setData = () => {
        if(modal.edit || modal.delete) {
            if(type === 'edit') {
                updateFormState(card.cardTitle, card.cardDesc, card.link);
            } else {
                resetForm();
            }
        }
    }

    useEffect(setData, [card, type, modal]);

    /**
     * This function handles creating card.
     */
    const handleCreate = () => {
        props.handleAction(formState);
        resetModal();
    }

    /**
     * This function handles editing card.
     */
    const handleEdit = () => {
        if(formState.title.trim() === card.cardTitle.trim() && 
            formState.description.trim() === card.cardDesc.trim() && 
            formState.link.trim() === card.link.trim()) {
            NotificationManager.info('No changes made.', '', 2000);
        } else {
            props.handleAction(cardKey, formState);
        }
        resetModal();
    }

    /**
     * This function handles deleting card.
     */
    const handleDelete = () => {
        props.handleAction(cardKey);
        resetModal();
    }

    /**
     * This function handles changing values of the fields.
     * @param { event } event - onChange event
     * @param { string } name - name of the component
     * @param { string } value - new value of the field
     */
    const handleChange = (event, { name, value }) => {
        let formStateClone = {...formState};
        formStateClone[name] = value;
        updateFormState(formStateClone.title, formStateClone.description, formStateClone.link);
    }

    return (
        type === 'create' || type === 'edit' ? 
            <Modal
                open={modal.edit}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                size='tiny'
                className='web-modal'
                trigger={
                    type === 'create' ?
                        <Card 
                            id='create-card'
                            className='link-card' 
                            centered
                            onClick={() => updateEditModal(true)} >
                            <Card.Content>
                                <Grid
                                    className='card-grid'
                                    relaxed
                                    columns='equal'
                                    verticalAlign='middle'>
                                    <Grid.Row className='compact-element'>
                                        <Grid.Column textAlign='center'>
                                            <Header as='h2' icon>
                                                <Icon name='plus' color='green' />
                                                Add Card
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Card>
                    :
                        <Icon
                            id={`edit-card-${cardIndex}`}
                            link
                            size='large'
                            name='pencil'
                            className='card-buttons'
                            onClick={() => updateEditModal(true)} />                      
                }>
                <Modal.Header className='modal-header'>{type === 'create' ? 'Create Card' : 'Edit Card'}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={type === 'create' ? handleCreate : handleEdit}>
                        <Form.Field 
                            required
                            id='card-title'
                            control={Input}
                            type='text'
                            label='Card Title'
                            placeholder='Title'
                            name='title'
                            value={formState.title}
                            onChange={handleChange} />
                        <Form.Field 
                            required
                            id='card-description'
                            control={TextArea}
                            type='text'
                            label='Card Description'
                            placeholder='Description'
                            name='description'
                            value={formState.description}
                            onChange={handleChange} />
                        <Form.Field 
                            required
                            id='card-link'
                            control={Input}
                            type='url'
                            label='Card Link'
                            placeholder='https://drive.google.com'
                            name='link'
                            value={formState.link}
                            onChange={handleChange} />
                        <Button 
                            id='submit-card-form'
                            type='submit'
                            className='invisible-element' />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id='update-card-button'
                        type='submit'
                        className='modal-buttons'
                        floated='right'
                        disabled={formState.title.trim() === '' || formState.description.trim() === '' || formState.link.trim() === ''}
                        content={type === 'create' ? 'Create' : 'Update'}
                        onClick={() => document.getElementById('submit-card-form').click()} />
                    <Button 
                        id='cancel-update-card-button'
                        type='button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
        :
            <Modal
                open={modal.delete}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                className='web-modal'
                size='mini'
                trigger={
                    <Icon
                        id={`delete-card-${cardIndex}`}
                        link
                        size='large'
                        name='trash'
                        className='card-buttons'
                        onClick={() => updateDeleteModal(true)} />
                }>
                <Modal.Header className='modal-header'>Delete Card</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete <strong>{card.cardTitle}</strong>?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        type='button'
                        id='delete-card-button'
                        className='modal-buttons'
                        floated='right'
                        content='Delete'
                        onClick={handleDelete} />
                    <Button 
                        type='button'
                        id='cancel-delete-card-button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
    )
}

LinkCardModal.propTypes = {
    type: PropTypes.string,
    cardKey: PropTypes.string,
    card: PropTypes.object,
    handleAction: PropTypes.func
}

export default LinkCardModal;