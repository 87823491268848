import { useState } from 'react'

export default function useEditAdminEmailState() {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [current, setCurrent] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [code, setCode] = useState('');
    const [OTP, setOTP] = useState(null);

    return {
        loading,
        step,
        current,
        newEmail,
        code,
        OTP,
        updateLoading: setLoading,
        updateStep: setStep,
        updateCurrent: setCurrent,
        updateNewEmail: setNewEmail,
        updateCode: setCode,
        updateOTP: setOTP
    }
}