/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useAnnouncementModalState from '../states/useAnnouncementModalState'
import { Button, Form, Icon, Input, Modal, TextArea } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/**
 * <AnnouncementModal   type
 *                      announcementKey [OPTIONAL]
 *                      announcement [OPTIONAL]
 *                      announcementIndex [OPTIONAL]
 *                      handleAction />
 * 
 * @prop { string } type - action type
 * @prop { string } announcementKey - object key
 * @prop { object } announcement - announcement object
 * @prop { integer } announcementIndex - index of announcement
 * @prop { function } handleAction - function to manipulate announcement
 */
function AnnouncementModal(props) {
    const { type, announcement, announcementKey, announcementIndex } = props;
    const {
        formState,
        modal,
        updateFormState,
        updateEditModal,
        updateDeleteModal,
        resetModal,
        resetForm
    } = useAnnouncementModalState();

    /**
     * Initialize data upon editing data.
     */
    const setData = () => {
        if(modal.edit || modal.delete) {
            if(type === 'edit') {
                updateFormState(announcement.title, announcement.body);
            } else {
                resetForm();
            }
        }
    }

    useEffect(setData, [announcement, type, modal]);

    /**
     * This function handles creating announcement.
     */
    const handleCreate = () => {
        props.handleAction(formState);
        resetModal();
    }

    /**
     * This function handles editing announcement.
     */
    const handleEdit = () => {
        if(formState.title.trim() === announcement.title.trim() && 
            formState.body.trim() === announcement.body.trim()) {
            NotificationManager.info('No changes made.', '', 2000);
        } else {
            props.handleAction(announcementKey, formState);
        }
        resetModal();
    }

    /**
     * This function handles deleting announcement.
     */
    const handleDelete = () => {
        props.handleAction(announcementKey);
        resetModal();
    }

    /**
     * This function handles changing values of the fields.
     * @param { event } event - onChange event
     * @param { string } name - name of the component
     * @param { string } value - new value of the field
     */
    const handleChange = (event, { name, value }) => {
        let formStateClone = {...formState};
        formStateClone[name] = value;
        updateFormState(formStateClone.title, formStateClone.body);
    }

    return (
        type === 'create' || type === 'edit' ? 
            <Modal
                open={modal.edit}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                size='tiny'
                className='web-modal'
                trigger={
                    type === 'create' ?
                        <Button
                            id='create-announcement'
                            className='page-buttons'
                            content='Add'
                            onClick={() => updateEditModal(true)} />
                    :
                        <Icon
                            id={`edit-announcement-${announcementIndex}`}
                            link
                            size='large'
                            name='pencil'
                            onClick={() => updateEditModal(true)} />
                }>
                <Modal.Header className='modal-header'>{type === 'create' ? 'Create Announcement' : 'Edit Announcement'}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={type === 'create' ? handleCreate : handleEdit}>
                        <Form.Field 
                            required
                            id='announcement-title'
                            control={Input}
                            type='text'
                            label='Announcement Title'
                            placeholder='Title'
                            name='title'
                            value={formState.title}
                            onChange={handleChange} />
                        <Form.Field 
                            required
                            id='announcement-body'
                            control={TextArea}
                            type='text'
                            label='Announcement Body'
                            placeholder='Body'
                            name='body'
                            value={formState.body}
                            onChange={handleChange} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        type='submit'
                        id='update-announcement-button'
                        className='modal-buttons'
                        floated='right'
                        disabled={formState.title.trim() === '' || formState.body.trim() === ''}
                        content={type === 'create' ? 'Create' : 'Update'}
                        onClick={type === 'create' ? handleCreate : handleEdit} />
                    <Button 
                        id='cancel-update-announcement-button'
                        type='button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
        :
            <Modal
                open={modal.delete}
                onClose={resetModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered
                className='web-modal'
                size='mini'
                trigger={
                    <Icon
                        id={`delete-announcement-${announcementIndex}`}
                        link
                        size='large'
                        name='trash'
                        onClick={() => updateDeleteModal(true)} />
                }>
                <Modal.Header className='modal-header'>Delete Announcement</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete <strong>{announcement.title}</strong>?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        type='button'
                        id='delete-announcement-button'
                        className='modal-buttons'
                        floated='right'
                        content='Delete'
                        onClick={handleDelete} />
                    <Button 
                        type='button'
                        id='cancel-delete-announcement-button'
                        floated='right'
                        content='Cancel'
                        onClick={resetModal} />
                </Modal.Actions>
            </Modal>
    )
}

AnnouncementModal.propTypes = {
    type: PropTypes.string,
    announcementKey: PropTypes.string,
    announcement: PropTypes.object,
    announcementIndex: PropTypes.number,
    handleAction: PropTypes.func
}

export default AnnouncementModal;