/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useDebtsState from '../../states/useDebtsState'
import { Grid, Header, Icon, List, Pagination } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../../utils/API'

/* COMPONENTS AND ASSETS */
import DebtsItem from '../../components/DebtsItem'

/**
 * <Debts   debts
 *          updateDebts
 *          updateLoading
 *          handleLogout />
 * 
 * @prop { array } debts - array of debts
 * @prop { function } updateDebts - function to update debts
 * @prop { function } updateLoading - function to trigger loading
 * @prop { function } handleLogout - function to logout user
 */
function Debts(props) {
    const { debts } = props;
    const {
        headers,
        list,
        activePage,
        totalPages,
        updateHeaders,
        updateList,
        updateActivePage,
        updateTotalPages
    } = useDebtsState();

    /**
     * This function calls back-end and fetch data upon rendering.
     */
    const fetchData = () => {
        props.updateLoading(true);
        let auth = new URLSearchParams({
            token: localStorage.getItem('token')
        })
        API.getDebts(auth, (response) => {
            if(response.success) {
                if(response.data.documents.length > 0 && response.data.documents !== '[]') {
                    let data = JSON.parse(response.data.documents);
                    setHeaders(_.find(data, { '_rowNumber': 2 }));
                    props.updateDebts(data || []);
                    updateTotalPages(data.length / 10);
                }
            } else {
                if(response.data.user === null) {
                    NotificationManager.error(response.message, 'Error', 2000);
                    props.handleLogout();
                } else {
                    NotificationManager.error(response.message, 'Error', 2000);
                }
            }
            props.updateLoading(false);
        })
    }

    useEffect(fetchData, []);

    /**
     * This function setup data.
     */
    const setData = () => {
        if(debts.length > 0) {
            setHeaders(_.find(debts, { '_rowNumber': 2 }));
            updateList(_.reverse(debts));
            updateTotalPages(debts.length / 10);
        }
    }

    useEffect(setData, [debts]);

    /**
     * This function sets the value of headers for the table.
     * @param { object } - sheet row object
     */
    const setHeaders = (row) => {
        updateHeaders(row['_sheet']['headerValues']);
    }

    /**
     * This function handles refreshing the table on change pagination.
     * @param { event } event - onClick event
     * @param { object } data - object contains the selected active page
     */
    const handleChangePage = (event, data) => {
        if((data.activePage - 1)*10 + 10 > debts.length) {
            updateList([...debts].slice((data.activePage - 1)*10, debts.length));
        } else {
            updateList([...debts].slice((data.activePage - 1)*10, (data.activePage - 1)*10 + 10));
        }
        updateActivePage(data.activePage);
    }

    return (
        <div className='page-content'>
            <Grid
                className='web-grid'
                relaxed
                columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            as='h2'
                            content={`${localStorage.getItem('session')} Debts Tracker`}
                            dividing />
                    </Grid.Column>
                </Grid.Row>
                {debts.length === 0 ?
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Header as='h3' icon>
                                <Icon name='inbox' />
                                No debts found.
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                : null}
                <Grid.Row className='compact-element'>
                    <Grid.Column>
                        <List divided relaxed>
                            {list.map((val, index) => (
                                <DebtsItem
                                    key={index}
                                    debt={val}
                                    debtIndex={index}
                                    headers={headers} />
                            ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {debts.length > 0 && totalPages > 1 ?
                <div className='pagination-wrapper'>
                    <Pagination 
                        activePage={activePage}
                        totalPages={totalPages}
                        onPageChange={handleChangePage} />
                </div>
            : null}
        </div>
    )
}

Debts.propTypes = {
    debts: PropTypes.array,
    updateDebts: PropTypes.func,
    updateLoading: PropTypes.func,
    handleLogout: PropTypes.func
}

export default Debts;