/* REACT AND CSS */
import React from 'react'
import PropTypes from 'prop-types'
import useDebtsItemState from '../states/useDebtsItemState'
import { Button, Grid, Item, Label, List, Modal, Responsive, Table } from 'semantic-ui-react'

/* EXTERNAL LIBRARIES */
import moment from 'moment'

/**
 * <DebtsItem   headers
 *              debt
 *              debtIndex />
 * 
 * @prop { array } headers - array of table headers
 * @prop { object } debt - sheet row object
 * @prop { integer } debtIndex - index of document
 */
function DebtsItem(props) {
    const { debt, debtIndex, headers } = props;
    const { 
        modal,
        updateModal,
    } = useDebtsItemState();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP'
    });

    return (
        <Modal
            className='web-modal'
            open={modal}
            onClose={() => updateModal(false)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size='tiny'
            trigger={
                <List.Item 
                    id={`debts-${debtIndex}`} 
                    className='docu-list' 
                    onClick={() => updateModal(true)}>
                    <Grid
                        className='web-grid'
                        relaxed
                        columns='equal'>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column className='compact-element'>
                                <Item>
                                    <Item.Header as='h4'>{`${debt['Title of Activity']}`}</Item.Header>
                                    <span as='h5'>{formatter.format(parseFloat(debt['Amount'].replace(',', '')))}</span>
                                </Item>
                            </Grid.Column>
                            <Responsive 
                                as={Grid.Column} 
                                minWidth={Responsive.onlyTablet.minWidth}
                                textAlign='center'
                                className='compact-element'>
                                <Item>
                                    <Item.Header as='h5'>{debt['Payable To']}</Item.Header>
                                </Item>
                            </Responsive>
                            <Grid.Column 
                                textAlign='right' 
                                verticalAlign='top'
                                className='compact-element'>
                                <Label
                                    size='large'
                                    color={debt['Settled'] === undefined || debt['Settled?'] === '' ? 'red' : 'green'}
                                    content={debt['Settled'] === undefined || debt['Settled?'] === '' ? 'Unsettled' : 'Settled'} />
                                <br />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='compact-element'>
                            <Grid.Column className='compact-element' textAlign='right'>
                                <Item>
                                    <Item.Description>{`Received on 
                                        ${moment(debt['Date Received'], 'M/DD/YYYY').format('MMM D, YYYY')}`
                                    }</Item.Description>
                                </Item>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </List.Item>
            }>
            <Modal.Header className='modal-header'>{debt['Title of Activity']}</Modal.Header>
            <Modal.Content scrolling>
                <Table size='small' celled>
                    <Table.Body>
                        {headers.length > 0 && headers.map((val, index) => (
                            <Table.Row key={index}>
                                <Table.Cell width='8'>{val}</Table.Cell>
                                <Table.Cell>{debt['_rawData'][index]}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    id='close-debts-modal'
                    className='modal-buttons'
                    floated='right'
                    content='Close'
                    onClick={() => updateModal(false)} />
            </Modal.Actions>
        </Modal>            
    )
}

DebtsItem.propTypes = {
    headers: PropTypes.array,
    debt: PropTypes.object,
    debtIndex: PropTypes.number
}

export default DebtsItem;