/* REACT AND CSS */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Transition } from 'semantic-ui-react'
import routes from './path'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'

/* COMPONENTS AND ASSETS */
import Landing from './Landing'
import Redirect from '../../components/Redirect'
import PageNotFound from '../../components/PageNotFound'

function PageRoutes() {
    const history = useHistory();
    const [redirect, setRedirect] = useState(true);
    const [validRoute, setValidRoute] = useState(false);

    const validateRoute = () => {
        const page = history.location.pathname.split('/').filter(val => val !== '');
        if(page.length === 1 || _.some(routes, { path: `/${page[1]}` })) {
            setValidRoute(true);
        } else {
            setValidRoute(false);
        }
        setRedirect(false);
    }

    useEffect(validateRoute, []);

    return (
        redirect ? <Redirect /> : 
        <Transition visible={!redirect} animation='fade'>
            {validRoute ? <Landing /> : <PageNotFound />}
        </Transition>
    )
}

export default PageRoutes;