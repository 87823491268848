import { useState } from 'react'

export default function useLinkCardModalState() {
    const [formState, setFormState] = useState({
        title: '', description: '', link: ''
    })
    const [modal, setModal] = useState({
        edit: false, delete: false
    });

    const handleUpdateFormState = (title, description, link) => {
        setFormState({ title, description, link });
    }

    const handleUpdateEditModal = (value) => {
        setModal({...modal, edit: value});
    }

    const handleUpdateDeleteModal = (value) => {
        setModal({...modal, delete: value});
    }

    const handleResetModal = () => {
        setModal({ edit: false, delete: false });
    }
    
    const handleResetForm = () => {
        setFormState({ title: '', description: '', link: '' });
    }

    return {
        formState,
        modal,
        updateFormState: handleUpdateFormState,
        updateEditModal: handleUpdateEditModal,
        updateDeleteModal: handleUpdateDeleteModal,
        resetModal: handleResetModal,
        resetForm: handleResetForm
    }
}