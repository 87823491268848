import { useState } from 'react'

export default function useUEditProfileState() {
    const [formState, setFormState] = useState({ userKey: '', head: '', email: '', trackerKey: '' });
    const [loading, setLoading] = useState(false);

    const handleUpdateFormState = (userKey, head, email, trackerKey ) => {
        setFormState({ userKey, head, email, trackerKey });
    }

    return {
        formState,
        loading,
        updateFormState: handleUpdateFormState,
        updateLoading: setLoading
    }
}