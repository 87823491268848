import { useState } from 'react'

export default function useFAQModalState() {
    const [formState, setFormState] = useState({ question: '', answer: '' })
    const [modal, setModal] = useState({
        edit: false, delete: false
    });

    const handleUpdateFormState = (question, answer) => {
        setFormState({ question, answer });
    }

    const handleUpdateEditModal = (value) => {
        setModal({ ...modal, edit: value });
    }

    const handleUpdateDeleteModal = (value) => {
        setModal({ ...modal, delete: value });
    }

    const handleResetModal = () => {
        setModal({ edit: false, delete: false });
    }

    const handleResetForm = () => {
        setFormState({ question: '', answer: '' });
    }

    return {
        formState,
        modal,
        updateFormState: handleUpdateFormState,
        updateEditModal: handleUpdateEditModal,
        updateDeleteModal: handleUpdateDeleteModal,
        resetModal: handleResetModal,
        resetForm: handleResetForm
    }
}