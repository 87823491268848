/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useDocumentTrackerState from '../../states/useDocumentTrackerState'
import { Checkbox, Dropdown, Grid, Header, Icon, List, Pagination, Responsive } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../../utils/API'

/* COMPONENTS AND ASSETS */
import SearchBox from '../../components/SearchBox'
import DocumentItem from '../../components/DocumentItem'

/**
 * <DocumentTracker     documents
 *                      updateDocuments
 *                      updateLoading
 *                      handleLogout />
 * 
 * @prop { array } documents - array of documents
 * @prop { function } updateDocuments - function to update documents
 * @prop { function } updateLoading - function to trigger loading
 * @prop { function } handleLogout - function to logout user
 */
function DocumentTracker(props) {
    const { documents } = props;
    const {
        headers,
        searchResult,
        searchText,
        baseKey,
        filter,
        activePage,
        totalPages,
        updateHeaders,
        updateSearchResult,
        updateSearchText,
        updateBaseKey,
        updateFilter,
        updateActivePage,
        updateTotalPages
    } = useDocumentTrackerState();

    const filterList = [
        {
            key: 'header1',
            content: (
                <Header 
                    as='h5' 
                    content='Status'
                    dividing />
            ),
            disabled: true
        },
        { key: 'header1-0', value: 'Approved', text: 'Approved', disabled: false },
        { key: 'header1-1', value: 'Pended', text: 'Pended', disabled: false },
        { key: 'header1-2', value: 'Processing', text: 'Processing', disabled: false },
        {
            key: 'header2',
            content: (
                <Header 
                    as='h5' 
                    content='Type of Process'
                    dividing />
            ),
            disabled: true
        },
        { key: 'header2-0', value: 'Book Transfer (BT)', text: 'Book Transfer (BT)', disabled: false },
        { key: 'header2-1', value: 'Cash Advance (CA)', text: 'Cash Advance (CA)', disabled: false },
        { key: 'header2-2', value: 'Change of Payee', text: 'Change of Payee', disabled: false },
        { key: 'header2-3', value: 'Direct Payment (DP)', text: 'Direct Payment (DP)', disabled: false },
        { key: 'header2-4', value: 'Discontinued Cash Advance', text: 'Discontinued Cash Advance', disabled: false },
        { key: 'header2-5', value: 'Fund Raising Activity Report (FRA)', text: 'Fund Raising Activity Report (FRA)', disabled: false },
        { key: 'header2-6', value: 'Liquidation (LQ)', text: 'Liquidation (LQ)', disabled: false },
        { key: 'header2-7', value: 'List of Expenses alone', text: 'List of expenses alone', disabled: false },
        { key: 'header2-8', value: 'No Expense (NE)', text: 'No Expense (NE)', disabled: false },
        { key: 'header2-9', value: 'Petty Cash Replenishment (PCR)', text: 'Petty Cash Replenishment (PCR)', disabled: false },
        { key: 'header2-10', value: 'Reimbursement (RM)', text: 'Reimbursement (RM)', disabled: false },
    ];

    /**
     * This function calls back-end and fetch data upon rendering.
     */
    const fetchData = () => {
        props.updateLoading(true);
        let auth = new URLSearchParams({
            token: localStorage.getItem('token')
        })
        API.getAllDocuments(auth, (response) => {
            if(response.success) {
                if(response.data.documents.length > 0 && response.data.documents !== '[]') {
                    let data = JSON.parse(response.data.documents);
                    setHeaders(_.find(data, { '_rowNumber': 2 }));
                    props.updateDocuments(_.reverse([...data]) || []);
                    updateTotalPages(Math.ceil(data.length / 10));
                }
            } else {
                if(response.data.user === null) {
                    NotificationManager.error(response.message, 'Error', 2000);
                    props.handleLogout();
                } else {
                    NotificationManager.error(response.message, 'Error', 2000);
                }
            }
            props.updateLoading(false);
        })
    }

    useEffect(fetchData, []);

    /**
     * This function setup data.
     */
    const setData = () => {
        if(documents.length > 0) {
            setHeaders(_.find(documents, { '_rowNumber': 2 }));
            if((activePage - 1)*10 + 10 > documents.length) {
                updateSearchResult(_.slice([...documents], (activePage - 1)*10, documents.length));
            } else {
                updateSearchResult(_.slice([...documents], (activePage - 1)*10, (activePage - 1)*10 + 10));
            }
            updateTotalPages(Math.ceil(documents.length / 10));
        }
    }

    useEffect(setData, [documents]);

    /**
     * This function sets the value of headers for the table.
     * @param { object } - sheet row object
     */
    const setHeaders = (row) => {
        updateHeaders(row['_sheet']['headerValues']);
    }

    /**
     * This function updates the list of documents on the screen.
     * @param { array } value - new updated array
     */
    const handleSearchResult = (value) => {
        updateSearchResult(value);
    }

    /**
     * This function handles filtering functionality.
     */
    const handleFilter = () => {
        if(filter !== '') {
            if(_.includes(['Approved', 'Pended', 'Processing'], filter)) {
                if(filter === 'Approved') {
                    updateSearchResult([...documents].filter(val => val['Remarks'] === filter && 
                                                        val[baseKey].toLowerCase().includes(searchText.toLowerCase())));
                } else if(filter === 'Pended') {
                    updateSearchResult([...documents].filter(val => val['Pended?'] === filter && 
                                                        val[baseKey].toLowerCase().includes(searchText.toLowerCase())));
                } else if(filter === 'Processing') {
                    updateSearchResult([...documents].filter(val => _.includes(['', undefined], val['Pended?']) && _.includes(['', undefined], val['Remarks']) && 
                                                        val[baseKey].toLowerCase().includes(searchText.toLowerCase())));
                }
            } else {
                updateSearchResult([...documents].filter(val => val['Type of Process'] === filter && 
                                                        val[baseKey].toLowerCase().includes(searchText.toLowerCase())));
            }
        } else {
            if(searchText !== '') {
                updateSearchResult([...documents].filter(val => val[baseKey].toLowerCase().includes(searchText.toLowerCase())));
            } else {
                resetSearch();
            }
        }
    }

    useEffect(handleFilter, [filter]);

    /**
     * This function resets search result.
     */
    const resetSearch = () => {
        if((activePage - 1)*10 + 10 > documents.length) {
            updateSearchResult(_.slice([...documents], (activePage - 1)*10, documents.length));
        } else {
            updateSearchResult(_.slice([...documents], (activePage - 1)*10, (activePage - 1)*10 + 10));
        }
        updateFilter('');
        updateSearchText('');
    }

    useEffect(resetSearch, [baseKey]);

    /**
     * This function handles refreshing the table on change pagination.
     * @param { event } event - onClick event
     * @param { object } data - object contains the selected active page
     */
    const handleChangePage = (event, data) => {
        if((data.activePage - 1)*10 + 10 > documents.length) {
            updateSearchResult(_.slice([...documents], (data.activePage - 1)*10, documents.length));
        } else {
            updateSearchResult(_.slice([...documents], (data.activePage - 1)*10, (data.activePage - 1)*10 + 10));
        }
        updateActivePage(data.activePage);
        updateFilter('');
        updateSearchText('');
    }

    return (
        <div className='page-content'>
            <Grid
                className='web-grid'
                relaxed
                columns='equal'
                verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column>
                        <Header
                            as='h2'
                            content={`${localStorage.getItem('session')} Finance Document Tracker`}
                            dividing />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='compact-element'>
                    <Grid.Column className='compact-element'>
                        <Grid
                            className='web-grid'
                            relaxed
                            columns='equal'
                            verticalAlign='middle'>
                            <Responsive as={Grid.Row} minWidth={Responsive.onlyComputer.minWidth}>
                                <Grid.Column>
                                    <SearchBox 
                                        data={[...documents]} 
                                        base={baseKey}
                                        placeholder={baseKey === 'Title of Activity' ? 'title' : 'person submitting'}
                                        searchText={searchText}
                                        handleSearchText={updateSearchText}
                                        handleSearchResult={handleSearchResult}
                                        handleResetFilter={() => updateFilter('')} />
                                </Grid.Column>
                                <Grid.Column textAlign='right' width={4}>
                                    <Dropdown
                                        id='filter-by-status'
                                        selection
                                        clearable
                                        fluid
                                        selectOnBlur={false}
                                        selectOnNavigation={false}
                                        placeholder='Select filter'
                                        options={filterList}
                                        value={filter}
                                        onChange={(event, { value }) => updateFilter(value)} />
                                </Grid.Column>
                            </Responsive>
                            <Responsive 
                                as={Grid.Row} 
                                minWidth={Responsive.onlyComputer.minWidth}
                                textAlign='left'>
                                <Grid.Column width={3}>
                                    <Checkbox 
                                        id='activity-title'
                                        radio
                                        label='Activity Title'
                                        value='Title of Activity'
                                        checked={baseKey === 'Title of Activity'}
                                        onChange={(event, { value }) => updateBaseKey(value)} />
                                </Grid.Column>
                                <Grid.Column className='compact-element'>
                                    <Checkbox 
                                        id='submitted-by'
                                        radio
                                        label='Submitted By'
                                        value='Submitted by'
                                        checked={baseKey === 'Submitted by'}
                                        onChange={(event, { value }) => updateBaseKey(value)} />
                                </Grid.Column>
                            </Responsive>
                            <Responsive as={Grid.Row} minWidth={Responsive.onlyComputer.minWidth} />
                            <Responsive 
                                as={Grid.Row}
                                maxWidth={Responsive.onlyTablet.maxWidth}>
                                <Responsive 
                                    as={Grid.Column}
                                    minWidth={Responsive.onlyTablet.minWidth}
                                    width={3} />
                                <Grid.Column>
                                    <SearchBox 
                                        data={[...documents]} 
                                        base={baseKey}
                                        placeholder={baseKey === 'Title of Activity' ? 'title' : 'person submitting'}
                                        searchText={searchText}
                                        handleSearchText={updateSearchText}
                                        handleSearchResult={handleSearchResult}
                                        handleResetFilter={() => updateFilter('')} />
                                </Grid.Column>
                                <Responsive 
                                    as={Grid.Column}
                                    minWidth={Responsive.onlyTablet.minWidth}
                                    width={3} />
                            </Responsive>
                            <Responsive 
                                as={Grid.Row} 
                                maxWidth={Responsive.onlyTablet.maxWidth}
                                textAlign='center'
                                className='compact-element'>
                                <Responsive 
                                    as={Grid.Column}
                                    minWidth={Responsive.onlyTablet.minWidth}
                                    width={3} />
                                <Grid.Column className='compact-element'>
                                    <Checkbox 
                                        id='activity-title'
                                        radio
                                        label='Activity Title'
                                        value='Title of Activity'
                                        checked={baseKey === 'Title of Activity'}
                                        onChange={(event, { value }) => updateBaseKey(value)} />
                                </Grid.Column>
                                <Grid.Column className='compact-element'>
                                    <Checkbox 
                                        id='submitted-by'
                                        radio
                                        label='Submitted By'
                                        value='Submitted by'
                                        checked={baseKey === 'Submitted by'}
                                        onChange={(event, { value }) => updateBaseKey(value)} />
                                </Grid.Column>
                                <Responsive 
                                    as={Grid.Column}
                                    minWidth={Responsive.onlyTablet.minWidth}
                                    width={3} />
                            </Responsive>
                        </Grid>       
                    </Grid.Column>
                </Grid.Row>
                <Responsive 
                    as={Grid.Row} 
                    textAlign='center'
                    maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Responsive 
                        as={Grid.Column}
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                    <Grid.Column>
                        <Dropdown
                            id='filter-by-status'
                            selection
                            clearable
                            fluid
                            selectOnBlur={false}
                            selectOnNavigation={false}
                            placeholder='Select filter'
                            options={filterList}
                            value={filter}
                            onChange={(event, { value }) => updateFilter(value)} />
                    </Grid.Column>
                    <Responsive 
                        as={Grid.Column}
                        minWidth={Responsive.onlyTablet.minWidth}
                        width={3} />
                </Responsive>
                {documents.length === 0 || searchResult.length === 0 || 
                    searchResult.filter(val => !_.includes(['#N/A', undefined], val['Timestamp'])).length === 0 ?
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Header as='h3' icon>
                                <Icon name='inbox' />
                                No documents found.
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                : null}
                <Grid.Row className='compact-element'>
                    <Grid.Column>
                        <List divided relaxed>
                            {searchResult.filter(val => !_.includes(['#N/A', undefined], val['Timestamp'])).map((val, index) => (
                                <DocumentItem 
                                    key={index} 
                                    documentIndex={index}
                                    headers={headers}
                                    document={val} />
                        ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {searchResult.filter(val => !_.includes(['#N/A', undefined], val['Timestamp'])).length > 0 &&
                searchResult.length > 0 && totalPages > 1 && filter === '' && searchText === '' ?
                <div className='pagination-wrapper'>
                    <Pagination 
                        activePage={activePage}
                        totalPages={totalPages}
                        onPageChange={handleChangePage} />
                </div>
            : null}
        </div>
    )
}

DocumentTracker.propTypes = {
    documents: PropTypes.array,
    updateDocuments: PropTypes.func,
    updateLoading: PropTypes.func,
    handleLogout: PropTypes.func
}

export default DocumentTracker;