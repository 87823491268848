/* REACT AND CSS */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useEditProfileState from '../states/useEditProfileState'
import { Button, Form, Input, Modal, Segment, TextArea } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

/* EXTERNAL LIBRARIES */
import _ from 'lodash'
import { API } from '../utils/API'

/**
 * <EditProfile     user
 *                  visible
 *                  updateLoading
 *                  updateCurrentUser
 *                  updateVisible />
 * 
 * @prop { object } user - user object
 * @prop { boolean } visible - modal visibility
 * @prop { function } updateLoading - function to update loading state
 * @prop { function } updateCurrentUser - function to update current user
 * @prop { function } updateVisible - function to update modal visibility
 */

function EditProfile(props) {
    const { user, visible } = props;
    const {
        formState,
        loading,
        updateFormState,
        updateLoading
    } = useEditProfileState();

    /**
     * Initialize data upon editing data.
     */
    const setData = () => {
        if(!_.isEmpty(user)) {
            let emails = '';
            let tracker = user.trackerKey === '' ? '' : `https://drive.google.com/file/d/${user.trackerKey}/view?usp=sharing`
            if(user.email !== undefined) {
                if(Array.isArray(user.email)) {
                    user.email.forEach((val, index) => {
                        emails += `${parseInt(index) > 0 ? ', ' : ''}${val}`
                    });
                } else {
                    emails = user.email;
                }
                updateFormState(user.username, user.head, emails, tracker);
            } else {
                updateFormState(user.username, user.head, '', tracker);
            }
        }
        updateLoading(false);
    }

    useEffect(setData, [user, visible]);

    /**
     * This function handles editing user.
     */
    const handleEdit = () => {
        if(_.isEqual(formState.head, user.head) &&
            _.isEqual(formState.email, user.email) &&
            _.isEqual(formState.trackerKey, user.trackerKey)) {
            NotificationManager.info('No changes made.', '', 2000);
        } else {
            let previous = user.username;
            let newUser = {...user};
            delete newUser.username;
            newUser.head = formState.head;
            newUser.trackerKey = formState.trackerKey === '' ? formState.trackerKey : formState.trackerKey.split('d/')[1].split('/')[0];
            let list = {};
            if(formState.email !== '') {
                formState.email.split(', ').forEach((val, index) => {
                    list[index] = val;
                })   
            }
            newUser.email = list;
            props.updateLoading(true);
            let data = new URLSearchParams({
                previous,
                username: formState.userKey,
                user: JSON.stringify(newUser),
            });
            props.updateVisible(false);
            API.users.update(data, (response) => {
                NotificationManager.success(response.message, 'Success', 2000);
                props.updateCurrentUser(response.data.user);
                props.updateLoading(false);
            })
        }
    }

    /**
     * This function validates email.
     */
    const handleValidateEmails = async () => {
        if(_.includes(formState.email.split(', '), formState.head)) {
            NotificationManager.error('Duplicate emails found!', '', 2000);
        } else {
            let list = [];
            if(formState.email !== '') {
                formState.email.split(', ').forEach(val => {
                    list.push(val);
                })
                updateLoading(true);
                handleEdit();
            }
        }
    }

    /**
     * This function handles changing values of the fields.
     * @param { event } event - onChange event
     * @param { string } name - name of the component
     * @param { string } value - new value of the field
     */
    const handleChange = (event, { name, value }) => {
        let formStateClone = {...formState};
        formStateClone[name] = value;
        updateFormState(formStateClone.userKey, formStateClone.head, formStateClone.email, formStateClone.trackerKey);
    }

    return (
        <Modal
            open={visible}
            onClose={() => props.updateVisibility(false)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            centered
            size='tiny'
            className='web-modal'>
            <Modal.Header className='modal-header'>
                {localStorage.getItem('admin') === '1' ? 'Edit Profile' : 'Organization Profile'}
            </Modal.Header>
            <Modal.Content>
                <Segment
                    as={Form}
                    loading={loading}
                    onSubmit={handleValidateEmails}>
                    <Form.Field 
                        id={'profile-org-name'}
                        required
                        readOnly
                        control={Input}
                        type='text'
                        label='Organization Name'
                        placeholder='Organization Name'
                        name='userKey'
                        value={formState.userKey}
                        onChange={handleChange} />
                    <Form.Field 
                        id={'profile-org-email'}
                        required
                        readOnly
                        control={Input}
                        type='email'
                        label={localStorage.getItem('admin') === '1' ? 'Admin Email Address' : 'Treasurer Email Address'}
                        placeholder='Email Address'
                        name='head'
                        value={formState.head}
                        onChange={handleChange} />
                    <Form.Field
                        id='profile-org-email-list'
                        readOnly={localStorage.getItem('admin') === '0'}
                        control={TextArea}
                        type='text'
                        label='Who Has Access'
                        placeholder='Ex. email1@gmail.com, email2@gmail.com'
                        name='email'
                        value={formState.email}
                        onChange={handleChange} />
                    <Form.Field
                        id={'profile-org-trackerKey'}
                        readOnly={localStorage.getItem('admin') === '0'}
                        control={Input}
                        type='text'
                        label='Document Tracker Link'
                        placeholder='Document Tracker Link'
                        name='trackerKey'
                        value={formState.trackerKey}
                        onChange={handleChange} />
                    {localStorage.getItem('admin') === '1' ?
                        <button
                            id='submit-profile-form'
                            type='submit'
                            className='invisible-element' />
                    : null}
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {localStorage.getItem('admin') === '0' ?
                    <Button
                        id={'cancel-update-profile-button'}
                        type='button'
                        className='modal-buttons'
                        floated='right'
                        content='Close'
                        onClick={() => props.updateVisible(false)} />
                :
                    <>
                        <Button
                            id={'update-profile-button'}
                            type='submit'
                            className='modal-buttons'
                            floated='right'
                            disabled={_.isEqual(formState.head, '')}
                            content='Update'
                            onClick={() => document.getElementById('submit-profile-form').click()} />
                        <Button
                            id={'cancel-update-profile-button'}
                            type='button'
                            floated='right'
                            content='Cancel'
                            onClick={() => props.updateVisible(false)} />
                    </>
                }
            </Modal.Actions>
        </Modal>
    )
}

EditProfile.propTypes = {
    user: PropTypes.object,
    visible: PropTypes.bool,
    updateLoading: PropTypes.func,
    updateCurrentUser: PropTypes.func,
    updateVisible: PropTypes.func
}

export default EditProfile;